import React, { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '~components/Pages';
import { reduxOperations, reducersTypes } from '~services';
import { getSocket } from '~services/socket';
import { ActionPropTypes } from '~services/actions/types';
import API from '~services/endpoints';
import { idToReadableExpr } from '~utils/parser';
import { sortArray } from '~utils/sort';
import { elementHasAllSelectedTags } from '~utils/tags';
import { showError, showSuccess } from '~utils/toast';
import { round } from '~utils/math';
import {
  variableIsCriticallyUsed,
  getCriticallyUsedErrorMessage,
  variableUsedByActionMessage,
  variableUsedByDashboardMessage,
} from '~utils/isVariableUsed';
import VariablePopupForm from './VariablePopUpForm';

const VARIABLES_UPDATE_RATE = 2500;

const filterVariables = (filter, selectedTags, variables) => variables.filter(variable => {
  const variableName = variable.variable.toUpperCase();
  const variableExpression = variable.expression.toUpperCase();
  return (variableName.indexOf(filter.toUpperCase()) > -1 || variableExpression.indexOf(filter.toUpperCase()) > -1)
    && elementHasAllSelectedTags(variable, selectedTags);
});

const sortList = (variables, sortInput) => {
  if (sortInput === 'byName') {
    return sortArray('alphabetically', variables, 'variable');
  }
  return variables;
};

const VariablesList = ({
  variables, addVariable, deleteVariable, filter,
  patchVariable, streams, machines, triggers, actions,
  sort, usedSelectedTags, dashboards,
}) => {
  const socket = getSocket();
  const { t } = useTranslation();
  const tags = useSelector(state => state.tags.tags);
  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

  const [showPopupForm, setShowPopupForm] = useState(false);
  const [modifiedItemId, setModifiedItemId] = useState('');
  const [variablesClone, setVariablesClone] = useState([]);
  const variablesCloneRef = useRef(variablesClone);
  const [variablesToShow, setVariablesToShow] = useState(variablesClone);
  const pendingUpdates = useRef({});
  const isUpdating = useRef(false);

  const parsedVariables = variables.map(variable => {
    const newVar = { ...variable };
    newVar.expression = idToReadableExpr(variable.expression, inputsAndVariables);
    return newVar;
  });

  const parseVariableName = variable => (
    <div className="variableName">
      { /* <Tooltip /> */}
      <span>{variable.variable}</span>
      {
        tags
          .filter(tag => variable.tags.includes(tag.id))
          .map(tag => (
            <div
              data-tip={tag.name}
              key={tag.id}
              className="cardTag"
              style={{ background: tag.color }}
            />
          ))
      }
    </div>
  );

  const isNotUsedStyleCondition = {
    style: { opacity: '0.6' },
    condition: input => !variableIsCriticallyUsed(input.id, actions, triggers, variables),
  };

  const nameDuplicate = (id, varName) => {
    const sameName = variables.find(variable => variable.variable === varName);
    return sameName;
  };

  const cloneAndCopy = obj => {
    const newObj = omit(variables.find(variable => variable.id === obj.id), ['id', 'dependencies', 'type', 'createdAt', 'createdBy', 'modifiedAt', 'modifiedBy']);
    let i = 1;
    let tail = '';
    // eslint-disable-next-line no-constant-condition
    while (true) {
      tail = `_${t('Copy').toLowerCase()}${i}`;
      let varName = newObj.variable;
      varName += tail;
      if (!nameDuplicate(newObj.id, varName)) break;
      i += 1;
    }

    newObj.variable += tail;

    const varTags = tags.filter(tag => newObj.tags.includes(tag.id));
    newObj.tags = varTags;
    return newObj;
  };

  const handleDuplicate = toClone => {
    const clone = cloneAndCopy(toClone);
    addVariable(clone)
      .then(() => showSuccess(t('showSuccessCreated')))
      .catch(() => showError(t('invalidFormData')));
  };

  const handleDelete = id => {
    const message = getCriticallyUsedErrorMessage(id, actions, triggers, variables);
    if (message) {
      showError(message);
      return;
    }
    deleteVariable(id);
  };

  const getDeleteType = id => {
    // Do not allow delete, variable is critical
    const isCritical = getCriticallyUsedErrorMessage(id, actions, triggers, variables);
    if (isCritical) {
      return { type: 'none', usedBy: isCritical };
    }
    // Allow delete with a warning that it is used but not critically
    const isUsed = variableUsedByActionMessage(id, actions)
      || variableUsedByDashboardMessage(id, dashboards);
    if (isUsed) {
      return { type: 'warning', usedBy: isUsed };
    }
    return { type: 'delete', usedBy: null };
  };

  const handleOnClick = id => {
    setModifiedItemId(id);
    setShowPopupForm(true);
  };

  const handleSocketData = socketData => {
    if (!variablesCloneRef.current.find(variable => variable.id === socketData.id)) {
      return;
    }

    if (typeof socketData.value === 'boolean') {
      pendingUpdates.current[socketData.id] = socketData.value ? 'true' : 'false';
    } else if (typeof socketData.value === 'string') {
      pendingUpdates.current[socketData.id] = `"${socketData.value}"`;
    } else {
      pendingUpdates.current[socketData.id] = socketData.value !== null ? (round(socketData.value, 4) || 0) : 'NaN';
    }

    if (isUpdating.current) {
      return;
    }

    isUpdating.current = true;
    setTimeout(() => {
      setVariablesClone(prevVariables => {
        const updatedVariables = prevVariables.map(variable => {
          const update = pendingUpdates.current[variable.id];
          return update !== undefined ? { ...variable, value: update } : variable;
        });

        pendingUpdates.current = {};
        isUpdating.current = false;

        return updatedVariables;
      });
    }, VARIABLES_UPDATE_RATE);
  };

  const fetchVariablesValues = async variablesToFetch => {
    const { values } = await API.getMultiplesValues(variables.map(variable => variable.id), {}, 1) || {};

    return variablesToFetch.map(variable => {
      const value = values?.find(val => val.valueId === variable.id)?.value;
      if (typeof value === 'boolean') {
        return {
          ...variable,
          value: value ? 'true' : 'false',
        };
      } if (typeof value === 'string') {
        return {
          ...variable,
          value: `"${value}"`,
        };
      }
      return {
        ...variable,
        value: value !== null ? (round(value, 4) || 0) : 'NaN',
      };
    });
  };

  useEffect(() => {
    async function fetchValues() {
      const fetchedVariables = await fetchVariablesValues(parsedVariables);
      setVariablesClone(fetchedVariables);
    }

    fetchValues();
    socket?.on('value', handleSocketData);
    return () => {
      socket?.removeListener('value', handleSocketData);
    };
  }, [socket, variables]);

  useEffect(() => {
    variablesCloneRef.current = variablesClone;
  }, [variablesClone]);

  useEffect(() => {
    setVariablesToShow(filterVariables(filter, usedSelectedTags, sortList(variablesClone, sort)));
  }, [filter, usedSelectedTags, sort, variablesClone]);

  return (
    <div>
      <VariablePopupForm show={showPopupForm} modifiedItemId={modifiedItemId} onHide={() => setShowPopupForm(false)} />
      {(variablesClone.length > 0 ? (
        <DefaultTable
          columnNames={[
            { name: t('name'), info: t('unusedVariableDescription') },
            { name: t('expression') },
            { name: t('realTimeValue') },
            { name: t('units') },
            { name: t('History'), info: t('historyDescription') },
          ]}
          entriesProperties={['variable', 'expression', 'value', 'units', 'toggle']}
          entriesStyle={[
            { property: 'variable', ...isNotUsedStyleCondition },
            { property: 'expression', ...isNotUsedStyleCondition },
            { property: 'value', ...isNotUsedStyleCondition },
            { property: 'units', ...isNotUsedStyleCondition },
          ]}
          entries={variablesToShow.map(varArg => ({ ...varArg, variable: parseVariableName(varArg) }))}
          editForm={VariablePopupForm}
          duplicateFunction={input => handleDuplicate(input)}
          deleteFunction={id => handleDelete(id)}
          toggleOnChange={(id, value) => patchVariable(id, { isEnabled: value })}
          deleteType={id => getDeleteType(id)}
          onClickEvent={id => handleOnClick(id)}
        />
      ) : <div>{t('noVariables')}</div>)}
    </div>
  );
};

VariablesList.propTypes = {
  addVariable: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  filter: PropTypes.string,
  patchVariable: PropTypes.func.isRequired,
  streams: reducersTypes.streams.isRequired,
  dashboards: reducersTypes.dashboards.dashboards.isRequired,
  variables: reducersTypes.variables.isRequired,
  machines: reducersTypes.machines.isRequired,
  triggers: reducersTypes.triggers.isRequired,
  actions: PropTypes.arrayOf(ActionPropTypes).isRequired,
  sort: PropTypes.func.isRequired,
  usedSelectedTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
VariablesList.defaultProps = {
  filter: '',
};

function mapStateToProps(state) {
  return {
    variables: state.variables,
    streams: state.streams,
    machines: state.machines,
    triggers: state.triggers,
    actions: state.actions,
    dashboards: state.dashboards.dashboards,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addVariable: reduxOperations.variables.addVariable,
    deleteVariable: reduxOperations.variables.deleteVariable,
    patchVariable: reduxOperations.variables.patchVariable,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VariablesList);
