const getTitle = (tile, language) => {
  if (!(tile.title || tile.titleEN || tile.titleFR || tile.titleES)) {
    return '';
  }
  switch (language) {
    case 'en':
      return tile.titleEN || tile.titleFR || tile.titleES || tile.title || '';
    case 'fr':
      return tile.titleFR || tile.titleEN || tile.titleES || tile.title || '';
    case 'es':
      return tile.titleES || tile.titleFR || tile.titleEN || tile.title || '';
    default:
      return tile.titleEN || tile.titleFR || tile.titleES || tile.title || '';
  }
};

function getTranslatedText(text, textEN, textFR, textES, language, featureToggles) {
  if (featureToggles.features?.tileTranslations) {
    switch (language) {
      case 'en':
        return textEN || textFR || textES || text || '';
      case 'fr':
        return textFR || textEN || textES || text || '';
      case 'es':
        return textES || textFR || textEN || text || '';
      default:
        return textEN || textFR || textES || text || '';
    }
  } else {
    return text || '';
  }
}

export { getTitle, getTranslatedText };
