import React, { useState, useEffect } from 'react';
import useInterval from 'use-interval';
import { useLocation, Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
//  import JSONEditor from '~components/CodeEditor/JSONEditor';
import { AudioPlayer } from '~components/AudioPlayer';
import { reduxOperations } from '~services';
import { PageTemplate } from '~components/Pages';
import { CreationHoverRoundButton, ResourcesHandler, FontAwesome } from '~UI';
import directLinks from '~utils/directLinks';
import { configurationFeature } from '~utils/featureToggles';
import Topview from '../Topviews/TopviewPage/Topview';
import Dashboard from './Dashboard/Dashboard';
import TileCreationForm from './Dashboard/FormTile/TileCreationForm';

const DashboardPage = ({ match }) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);
  const dashboards = useSelector(state => state.dashboards.dashboards);
  const resources = useSelector(state => [
    state.folders,
    state.forms,
    state.images.images,
    state.machines,
    state.operations.operations,
    state.operators,
    state.shifts.shifts,
    state.stopwatches.stopwatches,
    state.streams,
    state.triggers,
    state.variables,
  ]);

  const [index, setIndexState] = useState(-1);
  const [isUserAllowedToConfigure, setIsUserAllowedToConfigure] = useState(false);
  const [showCreateTile, setShowCreateTile] = useState(false);
  //  const [showJSONEditor, setShowJSONEditor] = useState(false);
  const topviews = useSelector(state => state.topviews.topviews);
  const [dashboard, setDashboard] = useState(dashboards.find(dash => dash.id === match.params.selectedDashboard));
  const [intervalMilliseconds, setIntervalMilliseconds] = useState(15000);

  useEffect(() => {
    setDashboard(dashboards.find(dash => dash.id === match.params.selectedDashboard));
  }, [dashboards]);

  useEffect(() => {
    setIntervalMilliseconds(() => {
      if (dashboard?.type === 'Multiple') {
        if (dashboard?.multipleDashboardItems?.length > 0 || dashboard?.dashboards.length > 0) {
          return dashboard.interval > 0 ? dashboard.interval * 1000 : 15000;
        }
      }

      return 15000;
    });
  }, [dashboard]);

  useInterval(() => {
    if (dashboard?.type === 'Multiple') {
      if (dashboard?.multipleDashboardItems?.length > 0) {
        setIndexState(prevIndex => (prevIndex + 1) % dashboard.multipleDashboardItems.length);
      }
      if (dashboard?.dashboards.length > 0) {
        setIndexState(prevIndex => (prevIndex + 1) % dashboard.dashboards.length);
      }
    }
  }, intervalMilliseconds);

  useEffect(() => {
    if (dashboard?.type === 'Multiple') {
      if (dashboard?.multipleDashboardItems?.length > 0) {
        setIndexState(prevIndex => (prevIndex + 1) % dashboard.multipleDashboardItems.length);
      }
      if (dashboard?.dashboards.length > 0) {
        setIndexState(prevIndex => (prevIndex + 1) % dashboard.dashboards.length);
      }
    }
    setIsUserAllowedToConfigure(configurationFeature.isUserAllowedAccessDashboards());
  }, []);

  useEffect(() => {
    const newIsUserAllowedToConfigure = configurationFeature.isUserAllowedAccessDashboards();
    if (isUserAllowedToConfigure !== newIsUserAllowedToConfigure) {
      setIsUserAllowedToConfigure(newIsUserAllowedToConfigure);
    }
  }, [isUserAllowedToConfigure]);

  const showCreateTileModal = () => (
    setShowCreateTile(true)
  );

  const hideCreateTileModal = () => (
    setShowCreateTile(false)
  );

  const getPage = () => {
    const currentDashboardId = match.params.selectedDashboard;
    let showedItem = dashboard;
    const currentDashboard = dashboard;
    if (currentDashboard?.type === 'Multiple') {
      if (dashboard?.dashboards.length > 0) {
        dashboard.multipleDashboardItems = [
          ...dashboard.dashboards.map(id => ({ id, type: 'dashboard' })),
        ];
      }
      if (currentDashboard?.multipleDashboardItems?.length > 0) {
        const allItems = currentDashboard.multipleDashboardItems || [];
        if (allItems.length > 0 && index >= 0) {
          const currentItem = allItems[index];
          if (currentItem.type === 'dashboard') {
            showedItem = dashboards.find(d => d.id === currentItem.id) || { tiles: [] };
          } else if (currentItem.type === 'topview') {
            showedItem = topviews.find(tv => tv.id === currentItem.id) || { widgets: [] };
          }
        }
      }
    }

    const isConfigurationAllowed = currentDashboard.type === 'Default' && isUserAllowedToConfigure && isInConfigurationMode;

    const creationButton = isConfigurationAllowed ? (
      <CreationHoverRoundButton
        position={1}
        pullDown
        onClick={showCreateTileModal}
      />
    ) : null;

    // const jsonButton = isConfigurationAllowed
    //   && configurationFeature.isUserAllowedAccessAdmin() && !showJSONEditor ? (
    //     <JSONButton
    //       position={2}
    //       pullDown
    //       onClick={() => setShowJSONEditor(prev => !prev)}
    //     />
    //   ) : null;
    const rightJSONEditor = isConfigurationAllowed
      && configurationFeature.isUserAllowedAccessAdmin() ? (
        <textarea />
      ) : null;

    const modifyIcon = isUserAllowedToConfigure ? (
      <Link to={location.pathname.startsWith('/config/') ? directLinks.dashboard(currentDashboardId) : directLinks.configDashboard(currentDashboardId)}>
        <FontAwesome icon="edit" style={{ color: isInConfigurationMode ? '#0078FF' : null }} />
      </Link>
    ) : null;

    return (

      <PageTemplate
        rightButtons={[creationButton]}
        right={[rightJSONEditor]}
        modifyIcon={modifyIcon}
        noScrollBar
        sidebar
        noHorizontalPadding
      >
        <AudioPlayer />
        <TileCreationForm isNewTile show={showCreateTile} onHide={hideCreateTileModal} />
        {
          showedItem?.type === 'Default' ? (
            <Dashboard dashboard={showedItem} isConfigurationAllowed={isConfigurationAllowed} />
          ) : (
            <Topview topview={showedItem} isConfigurationAllowed={isConfigurationAllowed} />
          )
        }

      </PageTemplate>
    );
  };

  return (
    <ResourcesHandler
      resources={[dashboards, ...resources]}
      resourceFetchers={[
        () => dispatch(reduxOperations.dashboards.fetchDashboards()),
        () => dispatch(reduxOperations.images.fetchImages()),
        () => dispatch(reduxOperations.folders.fetchFolders()),
        () => dispatch(reduxOperations.forms.fetchForms()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
        () => dispatch(reduxOperations.operators.fetchOperators()),
        () => dispatch(reduxOperations.shifts.fetchShifts()),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
      ]}
      getContents={getPage}
    />
  );
};

DashboardPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default withRouter(DashboardPage);
