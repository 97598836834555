import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { getStatisticsFrom } from '~components/utils';
import { FontAwesome } from '~UI';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { sortArray } from '~utils/sort';

import machineProperties from '../machineProperties.json';

const chooseDecimals = [
  'availability',
  'useRate',
  'overallEffectiveness',
  'totalEffectivePerformance',
  'operatingRate',
  'performanceRatio',
  'qualityRatio',
  'filledInStopCauseRatio',
  'plannedOutputCount',
  'hourlyPlannedOutputCount',
  'hourlyInputCount',
  'hourlyOutputCount',
  'hourlyAcceptableOutputCount',
  'hourlyScrapCount',
  'minutelyPlannedOutputCount',
  'minutelyInputCount',
  'minutelyOutputCount',
  'minutelyAcceptableOutputCount',
  'minutelyScrapCount',
  'productionRate',
  'minutelyProductionRate',
  'productionRatio',
  'minutelyProductionRatio',
  'plannedProductionRatio',
  'minutelyPlannedProductionRatio',
  'inputCount',
  'outputCount',
  'acceptableOutputCount',
  'scrapCount',
  'quantityLeft',
  'requiredQuantity',
];

const MachineForm = ({ selectedObject }) => {
  const { t } = useTranslation();

  const machines = useSelector(state => state.machines);
  const language = useSelector(state => state.views.language);
  const machinePropertiesOptions = machineProperties.map(property => (
    {
      label: t(property.name),
      value: property.property,
    }
  ));

  const findStopCauseInSubMenu = (stopCauses, selectedStopCauseId) => {
    for (let i = 0; i < stopCauses.length; i += 1) {
      const stopCause = stopCauses[i];
      if (stopCause.id === selectedStopCauseId) {
        return [stopCauses];
      }
      if (stopCause.subMenu) {
        const result = findStopCauseInSubMenu(stopCause.subMenu, selectedStopCauseId);
        if (result) {
          return [stopCauses, ...result];
        }
      }
    }
    return null;
  };

  const preSelectCause = (machineIdArg, machineProperty) => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let causes = null;
    let selectedCauseId = '';
    let menuStack = [];

    if (selectedMachine) {
      if (machineProperty === 'stopCauseDowntime') {
        causes = selectedMachine.stopCauses;
        selectedCauseId = selectedObject.machinePropertyStopCauseId;
      } else if (machineProperty === 'performanceCauseDropTime') {
        causes = selectedMachine.performanceCauses;
        selectedCauseId = selectedObject.machinePropertyPerformanceCauseId;
      }

      if (causes?.length) {
        menuStack = findStopCauseInSubMenu(causes, selectedCauseId);
        if (menuStack) {
          causes = menuStack[menuStack.length - 1];
        }
        return { selectedCauseId, causes, menuStack };
      }
    }

    return { selectedCauseId: '', causes: null, menuStack: [] };
  };

  const [machineId, setMachineId] = useState(selectedObject.machineId);
  const [machineProperty, setMachineProperty] = useState(selectedObject.machineProperty || 'availability');
  const [causesState, setCauses] = useState(null);
  const [selectedStopCauseIdState, setSelectedStopCauseId] = useState(selectedObject.machinePropertyStopCauseId);
  const [selectedPerformanceCauseIdState, setSelectedPerformanceCauseId] = useState(
    selectedObject.machinePropertyPerformanceCauseId);
  const [isSubMenuState, setIsSubMenu] = useState(null);
  const [currentOperation, setCurrentOperation] = useState(selectedObject.currentOperation || false);
  const [currentSKUNumber, setCurrentSKUNumber] = useState(selectedObject.currentSKUNumber || false);
  const [currentWorkOrder, setCurrentWorkOrder] = useState(selectedObject.currentWorkOrder || false);

  const [currentOperationVisibility, setCurrentOperationVisibility] = useState(false);
  const [currentSKUNumberVisibility, setCurrentSKUNumberVisibility] = useState(false);
  const [currentWorkOrderVisibility, setCurrentWorkOrderVisibility] = useState(false);

  const [intervalType, setIntervalType] = useState(selectedObject.intervalType);
  const [menuStack, setMenuStack] = useState([]);

  useEffect(() => {
    const preSelectCauseObj = preSelectCause(machineId, machineProperty);
    if (machineProperty === 'stopCauseDowntime') {
      setSelectedStopCauseId(preSelectCauseObj.selectedCauseId);
      setSelectedPerformanceCauseId(null);
    } else if (machineProperty === 'performanceCauseDropTime') {
      setSelectedPerformanceCauseId(preSelectCauseObj.selectedCauseId);
      setSelectedStopCauseId(null);
    }
    setCauses(preSelectCauseObj.causes);
    setIsSubMenu(preSelectCauseObj?.menuStack?.length > 1);
    setMenuStack(preSelectCauseObj.menuStack || []);
  }, [machineId, machineProperty]);

  const handleCauseSelection = cause => {
    if (cause === null && menuStack.length > 0) {
      const lastMenu = menuStack[menuStack.length - 1];
      const newMenuStack = menuStack.slice(0, -1);
      setMenuStack(newMenuStack);
      setIsSubMenu(newMenuStack.length > 0);
      setCauses(lastMenu);
    } else if (cause?.subMenu?.length) {
      const newMenuStack = [...menuStack, causesState];
      setMenuStack(newMenuStack);
      setCauses(cause.subMenu);
      setIsSubMenu(true);
    } else if (cause) {
      if (machineProperty === 'stopCauseDowntime') {
        setSelectedStopCauseId(cause.id);
      } else if (machineProperty === 'performanceCauseDropTime') {
        setSelectedPerformanceCauseId(cause.id);
      }
    }
  };
  const handleChangeMachineProperty = e => {
    if (getStatisticsFrom(e) === 'operation' && currentOperation) {
      setMachineProperty(e);
      setCurrentSKUNumber(false);
      setCurrentWorkOrder(false);
    } else {
      setIntervalType(prevIntervalType => {
        if (prevIntervalType === 'always') {
          setMachineProperty(e);
          return 'shift';
        }
        setMachineProperty(e);
      });
    }
  };

  const handleMachineIdChange = newMachineId => {
    const { selectedCauseId, causes } = preSelectCause(newMachineId, machineProperty);
    setMachineId(newMachineId);
    setIsSubMenu(menuStack?.length > 1);
    if (machineProperty === 'stopCauseDowntime') {
      setSelectedStopCauseId(selectedCauseId);
    } else if (machineProperty === 'performanceCauseDropTime') {
      setSelectedPerformanceCauseId(selectedCauseId);
    }
    setCauses(causes);
    setMenuStack(menuStack || []);
  };

  const handleToggleCurrentOperation = () => {
    setCurrentOperation(prevCurrentOperation => {
      if (prevCurrentOperation) {
        setIntervalType(prevIntervalType => {
          if (prevIntervalType === 'always') {
            return 'shift';
          }
          setIntervalType(prevIntervalType);
        });
        return false;
      }
      if (getStatisticsFrom(machineProperty) === 'operation') {
        setCurrentSKUNumber(false);
        setCurrentWorkOrder(false);
        return true;
      }
      return true;
    });
  };

  const handleToggleCurrentSKUNumber = () => {
    setCurrentSKUNumber(prevCurrentSKUNumber => !prevCurrentSKUNumber);
  };

  const handleToggleCurrentWorkOrder = () => {
    setCurrentWorkOrder(prevCurrentWorkOrder => !prevCurrentWorkOrder);
  };

  const { description } = machineProperties.filter(prop => machineProperty === prop.property)[0];
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  let machine = machines.find(m => m.id === selectedObject.machineId);
  let params = machine?.params || {};

  useEffect(() => {
    machine = machines.find(m => m.id === machineId);
    params = machine?.params || {};
    if (machineId === selectedObject.machineId) {
      setCurrentOperation(selectedObject.currentOperation || false);
      setCurrentSKUNumber(selectedObject.currentSKUNumber || false);
      setCurrentWorkOrder(selectedObject.currentWorkOrder || false);
    } else {
      setCurrentOperation(false);
      setCurrentSKUNumber(false);
      setCurrentWorkOrder(false);
    }

    setCurrentOperationVisibility(false);
    setCurrentSKUNumberVisibility(false);
    setCurrentWorkOrderVisibility(false);

    const { operation, skuNumber, workOrder } = params;

    if (operation && operation !== '') {
      setCurrentOperationVisibility(true);
    }

    if (skuNumber && skuNumber !== '') {
      setCurrentSKUNumberVisibility(true);
    }

    if (workOrder && workOrder !== '') {
      setCurrentWorkOrderVisibility(true);
    }
  }, [machineId]);

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('machineKPI')}</div>
      <Select
        name="machineProperty"
        className="fullwidth"
        value={machineProperty}
        onChange={handleChangeMachineProperty}
        options={machinePropertiesOptions}
        placeholder={t('select')}
      />
      <div style={{ marginBottom: '8px' }}>
        <FontAwesome icon="info-circle" />
        &nbsp;&nbsp;
        {t(description)}
      </div>

      <div>
        {
          currentOperationVisibility && (
            <div className="multipleSelectionInput">
              <Checkbox
                controlledCheck={currentOperation}
                checked={currentOperation}
                onChange={handleToggleCurrentOperation}
                label={t('currentOperation')}
              />
            </div>
          )}
        {
          currentSKUNumberVisibility && (
            <div className="multipleSelectionInput">
              <Checkbox
                controlledCheck={currentSKUNumber}
                checked={currentSKUNumber}
                onChange={handleToggleCurrentSKUNumber}
                label={t('currentSKUNumber')}
              />
            </div>
          )}
        {currentWorkOrderVisibility && (
          <div className="multipleSelectionInput">
            <Checkbox
              controlledCheck={currentWorkOrder}
              checked={currentWorkOrder}
              onChange={handleToggleCurrentWorkOrder}
              label={t('currentWorkOrder')}
            />
          </div>
        )}
      </div>

      <div>
        <input
          type="hidden"
          name="currentOperation"
          value={currentOperation}
        />
        <input
          type="hidden"
          name="currentSKUNumber"
          value={currentSKUNumber}
        />
        <input
          type="hidden"
          name="currentWorkOrder"
          value={currentWorkOrder}
        />
      </div>

      {
        (machineProperty === 'stopCauseDowntime' || machineProperty === 'performanceCauseDropTime') && (
          <>
            <input type="hidden" name="machinePropertyStopCauseId" value={selectedStopCauseIdState} />
            <input type="hidden" name="machinePropertyPerformanceCauseId" value={selectedPerformanceCauseIdState} />
            {
              isSubMenuState && (
                <FontAwesome
                  icon="arrow-left"
                  className="backButtonArrow"
                  style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
                  onClick={() => handleCauseSelection(null)}
                />
              )
            }
            {
              causesState && causesState.map(cause => {
                let causeName;
                switch (language) {
                  case 'en':
                    causeName = cause.nameEN || cause.name;
                    break;
                  case 'fr':
                    causeName = cause.nameFR || cause.name;
                    break;
                  case 'es':
                    causeName = cause.nameES || cause.name;
                    break;
                  default:
                    causeName = cause.name;
                }

                return (
                  <StopCauseIcon
                    key={cause.id}
                    option={{
                      ...cause,
                      name: causeName,
                      badgeCount: cause.subMenu?.length,
                    }}
                    small
                    className={(cause.id === (selectedStopCauseIdState || selectedPerformanceCauseIdState)) ? 'Selected' : 'Unselected'}
                    onClick={() => handleCauseSelection(cause)}
                    machineId={machineId}
                  />
                );
              })
            }
          </>
        )
      }
      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
        alwaysOption={currentOperation && getStatisticsFrom(machineProperty) === 'operation'}
        changeIntervalType={value => setIntervalType(value)}
      />
      {chooseDecimals.includes(machineProperty) && (
        <>
          <div className="inputTitle">{t('numberOfDecimals')}</div>
          <input
            type="number"
            name="decimals"
            className="fullwidth"
            min={0}
            max={4}
            defaultValue={Number.isInteger(selectedObject.decimals) ? selectedObject.decimals : 0}
          />
        </>
      )}
    </div>
  );
};

MachineForm.propTypes = {
  selectedObject: PropTypes.shape({
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    decimals: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
    machineProperty: PropTypes.string,
    machinePropertyStopCauseId: PropTypes.string,
    machinePropertyPerformanceCauseId: PropTypes.string,
  }),
};
MachineForm.defaultProps = {
  selectedObject: {},
};

export default MachineForm;
