import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '~services/store';
import { TextInput } from '~UI/index';
import { idToReadable, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './Forms.scss';

const FormText = ({ selectedObject, language }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);
  const defaultLanguage = useSelector((state: RootState) => state.views.language);
  const [textEN, setTextEN] = useState((selectedObject && selectedObject.textEN) || (defaultLanguage === 'en' ? selectedObject.text : ''));
  const [textFR, setTextFR] = useState((selectedObject && selectedObject.textFR) || (defaultLanguage === 'fr' ? selectedObject.text : ''));
  const [textES, setTextES] = useState((selectedObject && selectedObject.textES) || (defaultLanguage === 'es' ? selectedObject.text : ''));
  const [text, setText] = useState((selectedObject && selectedObject.text) || '');
  const [showError, setShowError] = useState(false);
  const featureToggles = useSelector((state: RootState) => state.settings.settings.featureToggles);

  const properties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const allVariables = [...properties, ...variables, ...kpis];
  const sortedVariables = sortArray('alphabetically', allVariables, 'variable').map(x => x.variable);
  sortedVariables.splice(0, 0, 'NOW');

  const handleTextChange = newText => {
    let id = newText;
    try {
      id = variableToId(newText, allVariables);
      setShowError(false);
    } catch (error) {
      // TODO: Error handling of non existent variable is not made and should be done
      setShowError(true);
    }
    if (featureToggles.features?.tileTranslations) {
      if (language === 'en') {
        setTextEN(id);
      } else if (language === 'fr') {
        setTextFR(id);
      } else if (language === 'es') {
        setTextES(id);
      }
      setText(id);
    } else {
      setText(id);
    }
  };

  const getCurrentText = () => {
    if (featureToggles.features?.tileTranslations) {
      if (language === 'en') {
        return textEN || text || '';
      } if (language === 'fr') {
        return textFR || text || '';
      } if (language === 'es') {
        return textES || text || '';
      }
    }
    return text || '';
  };

  const languageTitleMap = {
    en: textEN,
    fr: textFR,
    es: textES,
  };

  const defaultText = languageTitleMap[language];

  useEffect(() => {
    if (featureToggles.features?.tileTranslations) {
      setText(defaultText);
    }
  }, [defaultText]);

  return (
    <div className="Form">
      <div className="inputTitle">
        {t('text')}
        {' '}
        {showError && <span style={{ color: 'red', fontSize: '16px' }}>{t('variableUsedIsNotDefined')}</span>}
      </div>
      <TextInput
        className="fullwidth"
        Component="textarea"
        onChange={handleTextChange}
        options={sortedVariables}
        placeholder={t('triggerVariableList')}
        trigger="$"
        value={idToReadable(getCurrentText())}
      />
      {featureToggles.features?.tileTranslations && (
        <>
          <input type="hidden" name="text" value={defaultText} />
          <input type="hidden" name="textEN" value={textEN} />
          <input type="hidden" name="textFR" value={textFR} />
          <input type="hidden" name="textES" value={textES} />
        </>
      )}

      <input
        type="hidden"
        name="text"
        value={text}
      />
    </div>
  );
};

FormText.propTypes = {
  selectedObject: PropTypes.shape({
    text: PropTypes.string,
    textEN: PropTypes.string,
    textFR: PropTypes.string,
    textES: PropTypes.string,
  }),
  language: PropTypes.string.isRequired,
};
FormText.defaultProps = {
  selectedObject: {},
};

export { FormText };
