import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import emptyTopview from '~images/empty-topview-image.png';
import { reducersTypes } from '~services/index';

import TopviewWidget from '../TopviewWidget';
import { TopView } from '../Topview.interface';
import './Topview.scss';

interface Point {
  identifier: string;
  clientX: number;
  clientY: number;
}

const getImageDimensions = (url, next) => {
  const img = new Image();
  img.onload = () => next({
    width: img.naturalWidth,
    height: img.naturalHeight,
  });
  img.src = url;
};

const propTypes = {
  topview: reducersTypes.topviews.topview.isRequired,
  isConfigurationAllowed: PropTypes.bool.isRequired,
};

const Topview = ({ topview, isConfigurationAllowed }) => {
  let touchStart: Point[] = [];

  const [naturalWidth, setNaturalWidth] = useState(1);
  const [naturalHeight, setNaturalHeight] = useState(1);
  const [baseSizeFactor, setBaseSizeFactor] = useState(1); // so that baseSizeFactor * naturalWidth == window.innerWidth
  const [zoomLevel, setZoomLevel] = useState(1);
  const [sortedWidgets, setSortedWidgets] = useState<TopView[]>([]);

  const handlePinchStart = e => {
    if (e) {
      e.preventDefault();
    }
    // If the user makes simultaneous touches, the browser will fire a
    // separate touchstart event for each touch point. Thus if there are
    // three simultaneous touches, the first touchstart event will have
    // targetTouches length of one, the second event will have a length
    // of two, and so on.
    // Cache the touch points for later processing of 2-touch pinch/zoom
    if (e.targetTouches.length === 2) {
      touchStart = [...e.targetTouches];
    }
  };

  const handlePinchZoom = e => {
    // "pinch" zooming (on tablet or mobile)
    if (e.targetTouches.length === 2) {
      // Find the two target touches that are the same ones that started the 2-touch
      const point1 = touchStart
        .find(pt => pt.identifier === e.targetTouches[0].identifier);
      const point2 = touchStart
        .find(pt => pt.identifier === e.targetTouches[1].identifier);

      if (point1 && point2) {
        // Calculate the difference between the start and move coordinates
        const dx1 = Math.abs(point1.clientX - e.targetTouches[0].clientX);
        const dx2 = Math.abs(point2.clientX - e.targetTouches[1].clientX);
        const dy1 = Math.abs(point1.clientY - e.targetTouches[0].clientY);
        const dy2 = Math.abs(point2.clientY - e.targetTouches[1].clientY);

        const d1 = (dx1 * dx1) + (dy1 * dy1);
        const d2 = (dx2 * dx2) + (dy2 * dy2);

        const PINCH_THRESHHOLD = 100;
        if (d1 >= PINCH_THRESHHOLD && d2 >= PINCH_THRESHHOLD) {
          setZoomLevel(prevZoomLevel => (
            Math.max(1.0, Math.min(20.0, (prevZoomLevel * Math.sqrt(d2)) / Math.sqrt(d1)))
          ));
        }
      } else {
        touchStart = [];
      }
    }
  };

  const handlePinchMove = e => {
    if (e) {
      e.preventDefault();
    }
    // Check this event for 2-touch Move/Pinch/Zoom gesture
    handlePinchZoom(e);
  };

  const currentWidth = () => naturalWidth * baseSizeFactor * zoomLevel;

  const currentHeight = () => naturalHeight * baseSizeFactor * zoomLevel;

  const updateImage = ({ topview: topviewArg }) => {
    getImageDimensions(topviewArg.backgroundURL || emptyTopview, ({ width, height }) => {
      setNaturalWidth(width);
      setNaturalHeight(height);
      setBaseSizeFactor(window.innerWidth / width);
      setZoomLevel(1);
    });
  };

  const updateDimensions = () => {
    setNaturalWidth(prevNaturalWidth => {
      setBaseSizeFactor(window.innerWidth / prevNaturalWidth);
      return prevNaturalWidth;
    });
  };

  const updateZoomLevel = e => {
    if (e.ctrlKey) {
      e.preventDefault();
      if (e.deltaY < 0) {
        // zoom in
        setZoomLevel(prevZoomLevel => Math.min(20.0, prevZoomLevel + 0.5));
      } else {
        // zoom out
        setZoomLevel(prevZoomLevel => Math.max(1.0, prevZoomLevel - 0.5));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('wheel', updateZoomLevel);
    window.addEventListener('touchstart', handlePinchStart);
    window.addEventListener('touchmove', handlePinchMove);
    updateImage({ topview });

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('wheel', updateZoomLevel);
      window.removeEventListener('touchstart', handlePinchStart);
      window.removeEventListener('touchmove', handlePinchMove);
    };
  }, []);

  useEffect(() => {
    updateImage({ topview });
  }, [topview]);

  useEffect(() => {
    setSortedWidgets((topview.widgets || []).sort((a, b) => a.z - b.z));
  }, [topview.widgets]);
  return (
    <div
      className="Topview"
      style={{ width: currentWidth(), height: currentHeight() }}
    >
      <img
        src={topview.backgroundURL || emptyTopview}
        alt="Factory top view"
        draggable={false}
        width={currentWidth()}
        height={currentHeight()}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={currentWidth()}
        height={currentHeight()}
        viewBox={`0 0 ${naturalWidth} ${naturalHeight}`}
      >
        {
          sortedWidgets.map(widget => (
            <TopviewWidget
              key={widget.id}
              topviewId={topview.id}
              fetchDelay={topview.fetchDelay}
              widget={widget}
              maxX={naturalWidth}
              maxY={naturalHeight}
              magnitude={zoomLevel * baseSizeFactor}
              isConfigurationAllowed={isConfigurationAllowed}
            />
          ))
        }
      </svg>
    </div>
  );
};

Topview.propTypes = propTypes;

export default Topview;
