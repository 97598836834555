import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import { ButtonGroup, Input } from '@intelligenceindustrielle/react-ui-components';
import MetaDataDisplay from '~/pages/Config/MetaDataDisplay';
import {
  CancelButton, DeleteButton, DefaultModal, SubmitButton, FontAwesome,
} from '~UI';
import { reduxOperations } from '~services';
import { showError, showSuccess } from '~utils/toast';
import { getFormData } from '~utils';
import { splitTime } from '~utils/time';

const propTypes = {
  isCreating: PropTypes.bool.isRequired,
  stopwatches: PropTypes.arrayOf(PropTypes.shape({
    find: PropTypes.func,
  })).isRequired,
  modifiedItemId: PropTypes.string.isRequired,
  addStopwatch: PropTypes.func.isRequired,
  updateStopwatch: PropTypes.func.isRequired,
  deleteStopwatch: PropTypes.func.isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};

const defaultProps = {
  onHide: () => { },
  show: false,
};

const StopwatchCRUD = ({
  isCreating,
  stopwatches,
  modifiedItemId,
  show,
  onHide,
  addStopwatch,
  updateStopwatch,
  deleteStopwatch,
}) => {
  function getTimeFormat(stopwatchStartTime) {
    if (typeof stopwatchStartTime === 'number') {
      const hour = Math.floor(stopwatchStartTime / 3600000).toString().padStart(2, '0');
      let time = stopwatchStartTime % 3600000;
      const minute = Math.floor(time / 60000).toString().padStart(2, '0');
      time %= 60000;
      const second = Math.floor(time / 1000).toString().padStart(2, '0');
      return (`${hour}:${minute}:${second}`);
    }
    return '';
  }

  const { t } = useTranslation();
  const stopwatch = isCreating ? {} : stopwatches.find(watch => watch.id === modifiedItemId);
  const [stopwatchName, setStopwatchName] = useState(stopwatch.name || '');
  const [stopwatchType, setStopwatchType] = useState(stopwatch.type || 'countUp');
  const [stopwatchStartTime, setStopwatchStartTime] = useState(getTimeFormat(stopwatch.startTime));
  const users = useSelector(state => state.users.users);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('stopwatchForm');

    if (!formData.name) {
      showError(t('missingName'));
      return;
    }
    if (formData.name.includes(' ')) {
      showError(t('stopwatchNoSpaceInName'));
      return;
    }
    if (formData.type === 'CountDown' && formData.startTime <= 0) {
      showError(t('positiveCountdown'));
      return;
    }

    if (formData.type === 'CountDown') {
      const time = formData.startTime;
      const { hours, minutes, seconds } = splitTime(time);
      const totalMs = hours * 3600000 + minutes * 60000 + seconds * 1000;
      formData.startTime = totalMs;
    }

    if (isCreating) {
      addStopwatch(formData)
        .then(() => {
          showSuccess(t('stopwatchCreated'));
          setStopwatchName('');
          setStopwatchType('CountUp');
          setStopwatchStartTime('');
          onHide();
        })
        .catch(error => {
          showError(error.status);
        });
    } else {
      formData.startTime = formData.startTime || 0;
      updateStopwatch(modifiedItemId, formData)
        .then(() => {
          showSuccess(t('stopwatchUpdated'));
          onHide();
        })
        .catch(error => {
          showError(error.status);
        });
    }
  };

  const handleDeleteStopwatch = () => {
    deleteStopwatch(modifiedItemId)
      .then(() => {
        showSuccess(t('stopwatchDeleted'));
        onHide();
      })
      .catch(error => {
        showError(error.status);
      });
  };

  const handleChangeName = value => {
    setStopwatchName(value);
  };

  const handleChangeType = newStopwatchType => {
    setStopwatchType(newStopwatchType);
  };

  const handleChangeStartTime = e => {
    setStopwatchStartTime(e.currentTarget.value);
  };

  const types = [
    {
      value: 'CountUp',
      label: (
        <>
          {t('CountUp')}
          {' '}
          <FontAwesome icon="ii-stopwatch" />
        </>
      ),
    },
    {
      value: 'CountDown',
      label: (
        <>
          {t('CountDown')}
          {' '}
          <FontAwesome icon="hourglass" />
        </>
      ),
    },
  ];

  return (
    <DefaultModal
      show={show}
      title={isCreating ? t('createStopwatch') : t('saveStopwatch')}
      closePopup={onHide}
      children={(
        <form
          id="stopwatchForm"
          onSubmit={handleSubmit}
        >
          <div className="inputTitle">{t('name')}</div>
          <Input
            name="name"
            onChange={handleChangeName}
            placeholder={t('stopwatchName')}
            value={stopwatchName}
          />

          <div className="inputTitle">{t('type')}</div>
          <ButtonGroup
            buttons={types}
            name="type"
            onChange={handleChangeType}
            value={stopwatchType}
          />

          {stopwatchType === 'CountDown'
            ? (
              <>
                <div className="inputTitle">{t('startTime')}</div>
                <Cleave
                  placeholder="00:00:00"
                  options={{
                    time: true,
                    timePattern: ['h', 'm', 's'],
                  }}
                  onChange={handleChangeStartTime}
                  value={stopwatchStartTime}
                  name="startTime"
                />
              </>
            )
            : null
          }

          {modifiedItemId && (
            <MetaDataDisplay
              createdAt={stopwatch?.createdAt}
              createdBy={stopwatch?.createdBy}
              modifiedAt={stopwatch?.modifiedAt}
              modifiedBy={stopwatch?.modifiedBy}
              users={users}
            />
          )}

          <div className="buttonsHolder flexSpaceBetween">
            {
              !isCreating ? (
                <DeleteButton handleDelete={handleDeleteStopwatch} askConfirmation />
              ) : <div />
            }
            <div>
              <CancelButton onClick={onHide} />
              <SubmitButton
                label={isCreating ? t('add') : t('modify')}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

StopwatchCRUD.propTypes = propTypes;
StopwatchCRUD.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    language: state.views.language,
    stopwatches: state.stopwatches.stopwatches,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    deleteStopwatch: reduxOperations.stopwatches.deleteStopwatch,
    addStopwatch: reduxOperations.stopwatches.addStopwatch,
    updateStopwatch: reduxOperations.stopwatches.updateStopwatch,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StopwatchCRUD);
