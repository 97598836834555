import React from 'react';
import PropTypes from 'prop-types';

import { FormText } from '~components/forms';

function FormTileText({ selectedTile, language }) {
  return (
    <FormText
      selectedObject={selectedTile}
      language={language}
    />
  );
}

FormTileText.propTypes = {
  selectedTile: PropTypes.object,
  language: PropTypes.string.isRequired,
};
FormTileText.defaultProps = {
  selectedTile: {},
};

export default FormTileText;
