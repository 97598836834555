/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input, Select, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import Forms from '~components/forms';
import {
  FormStyle,
  shouldDisplayFormatInput,
  shouldDisplayLinksToInput,
  shouldDisplayTitleInput,
} from '~components/forms/FormStyle';
import { reduxOperations } from '~services';
import { CancelButton, DeleteButton, SubmitButton } from '~UI';

const WidgetEdition = ({
  handleSubmit, navSelected, onHide, topviewId, widget, widgetType,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const featureToggles = useSelector(state => state.settings.settings.featureToggles);
  const defaultLanguage = useSelector(state => state.views.language);

  const [title, setTitle] = useState(widget.title || '');
  const [titleEN, setTitleEN] = useState(widget.titleEN || (defaultLanguage === 'en' ? widget.title : ''));
  const [titleFR, setTitleFR] = useState(widget.titleFR || (defaultLanguage === 'fr' ? widget.title : ''));
  const [titleES, setTitleES] = useState(widget.titleES || (defaultLanguage === 'es' ? widget.title : ''));
  const [language, setLanguage] = useState(defaultLanguage || localStorage.getItem('i18nextLng') || 'fr');

  const updateTitle = (value, lang) => {
    switch (lang) {
      case 'en':
        setTitleEN(value);
        break;
      case 'fr':
        setTitleFR(value);
        break;
      case 'es':
        setTitleES(value);
        break;
      default:
        break;
    }
  };

  const languageButtons = [{
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  {
    label: 'ES',
    value: 'es',
  }];

  const languageTitleMap = {
    en: titleEN,
    fr: titleFR,
    es: titleES,
  };

  const displayTitle = languageTitleMap[language];

  function handleDelete() {
    dispatch(reduxOperations.topviews.deleteWidget(topviewId, widget.id));
    onHide();
  }

  const Form = Forms[widgetType] || Forms.empty;
  return (
    <form
      id="WidgetForm"
      onSubmit={e => e.preventDefault()}
    >
      <div
        className={classnames({
          hiddenDiv: navSelected !== 'parameters' && navSelected !== 'advanced',
        })}
      >
        {shouldDisplayTitleInput(widgetType) && (
          <div
            className={classnames({
              hiddenDiv: navSelected !== 'parameters',
            })}
          >
            <div className="inputTitle">{t('title')}</div>
            {featureToggles.features?.tileTranslations
              ? (
                <div className="languageInput">
                  <div className="titleInput">
                    <Input
                      value={displayTitle}
                      onChange={e => updateTitle(e, language)}
                      placeholder={t('valueInput')}
                    />
                  </div>
                  <ButtonGroup
                    buttons={languageButtons}
                    onChange={setLanguage}
                    value={language}
                  />
                  <input type="hidden" name="title" value={displayTitle} />
                  <input type="hidden" name="titleEN" value={titleEN} />
                  <input type="hidden" name="titleFR" value={titleFR} />
                  <input type="hidden" name="titleES" value={titleES} />
                </div>
              ) : (
                <Input
                  value={title}
                  onChange={setTitle}
                  name="title"
                  placeholder={t('valueInput')}
                />
              )}

          </div>
        )}

        {shouldDisplayFormatInput(widgetType) && (
          <div className={classnames({
            hiddenDiv: navSelected !== 'parameters',
          })}
          >
            <div className="inputTitle">{t('Shape')}</div>
            <Select
              name="shape"
              defaultValue={(widget && widget.format && widget.format.shape) || 'rect'}
              className="fullwidth"
              options={[
                { label: 'Rectangle', value: 'rect' },
                { label: t('Circle'), value: 'circle' },
              ]}
            />
          </div>
        )}

        <Form
          selectedObject={widget}
          selectedType={widgetType}
          navSelected={navSelected}
          isTile={false}
          language={language}
        />

        {shouldDisplayLinksToInput(widgetType) && (
          <div
            className={classnames({
              hiddenDiv: navSelected !== 'parameters',
            })}
          >
            <div className="inputTitle">{t('LinksTo')}</div>
            <Input
              defaultValue={(widget && widget.linksTo) || ''}
              name="linksTo"
              placeholder={t('valueInput')}
            />
          </div>
        )}
      </div>

      <div
        className={classnames({
          hiddenDiv: navSelected !== 'style',
        })}
      >
        <FormStyle
          selectedObject={widget}
          type={widgetType}
        />
      </div>

      <div className="buttonsHolder flexSpaceBetween">
        {
          widget ? (
            <DeleteButton handleDelete={handleDelete} askConfirmation />
          ) : <div />
        }
        <div>
          <CancelButton onClick={onHide} />
          <SubmitButton onClick={handleSubmit} label={widget ? t('modify') : t('add')} />
        </div>
      </div>
    </form>
  );
};

WidgetEdition.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  navSelected: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  topviewId: PropTypes.string.isRequired,
  widget: PropTypes.shape({
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    id: PropTypes.string,
    linksTo: PropTypes.string,
    title: PropTypes.string,
    titleEN: PropTypes.string,
    titleFR: PropTypes.string,
    titleES: PropTypes.string,
  }),
  widgetType: PropTypes.string.isRequired,
};
WidgetEdition.defaultProps = {
  widget: {},
};

export { WidgetEdition };
