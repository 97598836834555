import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import {
  DeleteButton, DefaultModal, CancelButton, SubmitButton, TextInput, FontAwesome,
} from '~UI';
import { reduxOperations } from '~services';
import MetaDataDisplay from '~/pages/Config/MetaDataDisplay';
import { showError, showSuccess } from '~utils/toast';
import { idToReadableExpr, variableToId, verifyVariableName, validateVarNamesDollarSign } from '~utils/parser';
import { getFormData } from '~utils';
import TagsInput from '~UI/Tags/TagsInput';
import { idsToTags } from '~utils/tags';
import { sortArray } from '~utils/sort';
import { allActionsUsingThisVariable, allTriggersUsingThisVariable, allVariablesUsingThisVariable, getCriticallyUsedErrorMessage } from '~utils/isVariableUsed';
import './VariablesPage.scss';

const propTypes = {
  // explicitly given props
  show: PropTypes.bool,
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func,
};

const defaultProps = {
  onHide: () => { },
  show: false,
  modifiedItemId: '',
};

const VariablePopUpForm = ({ show, modifiedItemId, onHide }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const triggers = useSelector(state => state.triggers);
  const actions = useSelector(state => state.actions);
  const tags = useSelector(state => state.tags.tags);

  const [selectedItemId, setSelectedItemId] = useState(modifiedItemId);

  const variable = variables.find(v => v.id === selectedItemId);
  let inputProperties = [].concat(...streams.map(s => s.properties));
  let kpis = [].concat(...machines.map(m => m.kpis || []));
  let inputsAndVariables = [...inputProperties, ...variables, ...kpis];

  const [variableName, setVariableName] = useState((variable && variable.variable) || '');
  const [expression, setExpression] = useState(variable ? idToReadableExpr(variable.expression, inputsAndVariables) : '');
  const [variableUnits, setVariableUnits] = useState((variable && variable.units) || '');
  const [variableTags, setVariableTags] = useState(variable && idsToTags(variable.tags, tags));

  const [openUsage, setOpenUsage] = useState(false);

  const users = useSelector(state => state.users.users);

  useEffect(() => {
    const newInputProperties = [].concat(...streams.map(s => s.properties));
    const newKpis = [].concat(...machines.map(m => m.kpis || []));
    const newInputsAndVariables = [...newInputProperties, ...variables, ...newKpis];
    const newVariable = variables.find(v => v.id === selectedItemId);

    setVariableName((newVariable && newVariable.variable) || '');
    setExpression(newVariable ? idToReadableExpr(newVariable.expression, newInputsAndVariables) : '');
    setVariableUnits((newVariable && newVariable.units) || '');
    setVariableTags(newVariable && idsToTags(newVariable.tags, tags));
    setOpenUsage(false);
  }, [show, selectedItemId]);

  useEffect(() => {
    if (modifiedItemId) {
      setSelectedItemId(modifiedItemId);
    }
  }, [modifiedItemId, show]);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('variableForm');

    if (!formData || !formData.expression) {
      showError(t('showErrorFields'));
      return;
    }
    const segments = formData.expression.split(/(".*?")/); // exclude strings
    const withoutStrings = segments.filter((_, i) => i % 2 === 0).join('');
    if ((withoutStrings.match(/\)/g) || []).length !== (withoutStrings.match(/\(/g) || []).length) {
      showError(t('expressionIsMalformedParenthesis'));
      return;
    }
    if (withoutStrings.includes(',')) {
      showError(t('formulExpressTooltip'));
      return;
    }
    if (verifyVariableName(formData.variable)) {
      return;
    }

    const properties = [].concat(...streams.map(s => s.properties));
    const newKpis = [].concat(...machines.map(m => m.kpis || []));
    const newInputsAndVariables = [...properties, ...variables, ...newKpis];
    const sameName = newInputsAndVariables.find(v => {
      if (v.variable === formData.variable) {
        return !selectedItemId || selectedItemId !== v.id;
      }
      return false;
    });
    if (sameName) {
      showError(t('variableNameExistsError'));
      return;
    }

    formData.tags = variableTags;
    formData.units = formData.units || null;

    if (validateVarNamesDollarSign(formData.expression)) {
      return;
    }
    try {
      formData.expression = variableToId(formData.expression, newInputsAndVariables);
    } catch (error) {
      if (error.message === 'Variable does not exist') {
        showError(t('variableUsedIsNotDefined'));
        return;
      }
    }

    if (selectedItemId) {
      if (formData.expression.includes(selectedItemId)) {
        showError(t('circularDependencyError'));
        return;
      }
      dispatch(reduxOperations.variables.patchVariable(selectedItemId, formData))
        .then(() => {
          onHide();
          dispatch(reduxOperations.tags.forceFetchTags());
          showSuccess(t('showSuccessUpdated'));
        })
        .catch(() => {
          showError(t('operationFailed'));
        });
    } else {
      dispatch(reduxOperations.variables.addVariable(formData))
        .then(() => {
          onHide();
          dispatch(reduxOperations.tags.forceFetchTags());
          showSuccess(t('showSuccessAdded'));
        })
        .catch(() => {
          showError(t('operationFailed'));
        });
    }
  };

  const handleDelete = async e => {
    const message = getCriticallyUsedErrorMessage(selectedItemId, actions, triggers, variables);
    if (message) {
      showError(message);
      e.preventDefault();
      onHide();
      return;
    }
    dispatch(reduxOperations.variables.deleteVariable(selectedItemId));
    onHide();
  };

  const onNameChange = e => {
    setVariableName(e.target.value);
  };

  const onExpressionChange = expressionArg => {
    setExpression(expressionArg);
  };

  const onUnitsChange = e => {
    setVariableUnits(e.target.value);
  };

  inputProperties = [].concat(...streams.map(s => s.properties));
  kpis = [].concat(...machines.map(m => m.kpis || []));
  inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable')
    .filter(x => x.id !== selectedItemId)
    .map(x => x.variable);

  inputsAndVariables.splice(0, 0, 'NOW');

  return (
    <DefaultModal
      title={selectedItemId ? t('modifyVariable') : t('createVariable')}
      show={show}
      closePopup={onHide}
      children={(
        <form
          id="variableForm"
          onSubmit={handleSubmit}
        >
          <div className="inputTitle">{t('varName')}</div>
          <input
            name="variable"
            type="text"
            className="fullwidth"
            value={variableName}
            onChange={onNameChange}
          />
          <div className="inputTitle">
            {t('formulExpress')}
            <Icon
              icon="info-circle"
              tooltipLabel={t('formulExpressTooltip')}
              style={{ position: 'relative', top: '2px' }}
            />
          </div>
          <TextInput
            options={inputsAndVariables}
            name="expression"
            className="fullwidth"
            trigger="$"
            value={expression}
            onChange={e => onExpressionChange(e)}
            placeholder={t('triggerVariableList')}
          />
          <div style={{ marginBottom: '8px' }}>
            <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
            {t('stringInVariables')}
          </div>
          <div className="inputTitle">{t('unitMeasurement')}</div>
          <input
            name="units"
            type="text"
            className="fullwidth"
            value={variableUnits}
            onChange={onUnitsChange}
          />
          <div className="inputTitle">{t('tags')}</div>
          <TagsInput
            tags={tags}
            currentTags={variableTags || []}
            modifyTags={newTags => setVariableTags(newTags)}
          />
          {selectedItemId && (
            <MetaDataDisplay
              createdAt={variable?.createdAt}
              createdBy={variable?.createdBy}
              modifiedAt={variable?.modifiedAt}
              modifiedBy={variable?.modifiedBy}
              users={users}
            />
          )}
          <div className="lineUsage" />
          {selectedItemId && (
            <div className="titleContainer">
              <div className="inputTitle">
                {t('usage')}
              </div>
              {openUsage && (
                <div className="usageTypeContainer">
                  <Badge pill className="usageType">
                    {t('variable')}
                  </Badge>
                  &nbsp;
                  <Badge pill className="usageType" style={{ backgroundColor: '#0078FF' }}>
                    {t('trigger')}
                  </Badge>
                  &nbsp;
                  <Badge pill className="usageType" style={{ backgroundColor: '#1E871E' }}>
                    {t('action')}
                  </Badge>
                </div>
              )}
              <span
                className={`arrowRight ${openUsage ? 'rotate' : ''}`}
                style={{ cursor: 'pointer' }}
                onClick={() => setOpenUsage(!openUsage)}
              >
                &rang;
              </span>
            </div>
          )}
          {selectedItemId && openUsage && (
            <div className="usageBadgeContainer">
              {allVariablesUsingThisVariable(selectedItemId, variables).map(v => (
                <Badge pill className="usageBadge" key={v.id} onClick={() => setSelectedItemId(v.id)}>
                  {v.variable}
                </Badge>
              ))}
              {allTriggersUsingThisVariable(selectedItemId, triggers).map(trigger => (
                <Link
                  key="/config/ruleEdition/:id"
                  to={`/config/ruleEdition/${trigger.id}`}
                >
                  <Badge pill className="usageBadge" key={trigger.id} style={{ backgroundColor: '#0078FF' }}>
                    {trigger.name}
                  </Badge>
                </Link>
              ))}
              {allActionsUsingThisVariable(selectedItemId, actions).map(action => (
                <Link
                  key="/config/actions"
                  to="/config/actions"
                >
                  <Badge pill className="usageBadge" key={action.id} style={{ backgroundColor: '#1E871E' }}>
                    {action.name}
                  </Badge>
                </Link>
              ))}
            </div>
          )}
          <div className="buttonsHolder flexSpaceBetween">
            {
              selectedItemId ? (
                <DeleteButton handleDelete={e => handleDelete(e)} askConfirmation />
              ) : <div />
            }
            <div>
              <CancelButton onClick={onHide} />
              <SubmitButton
                label={selectedItemId ? t('modify')
                  : t('create')}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

VariablePopUpForm.propTypes = propTypes;
VariablePopUpForm.defaultProps = defaultProps;

export default VariablePopUpForm;
