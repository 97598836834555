import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { NavigationTabs, shouldDisplayFormContent } from '~components/forms/NavigationTabs';
import RuleEditor from '~components/forms/Rule/RuleEditor';
import emptyTopview from '~images/empty-topview-image.png';
import { widgetTypes } from '~utils/types';
import { showError, showSuccess } from '~utils/toast';
import { reduxOperations } from '~services/index';
import { getFormData } from '~utils/index';
import { DefaultModal, FontAwesome } from '~components/UI';
import { RootState } from '~services/store';
import { WidgetEdition } from './WidgetEdition';
import { widgetOptions } from './widgetOptions';

const getImageDimensions = (url): Promise<{width: number, height: number}> => new Promise(resolve => {
  const img = new Image();
  img.onload = () => resolve({
    width: img.naturalWidth,
    height: img.naturalHeight,
  });
  img.src = url;
});

const TopviewWidgetEditionForm = ({
  onHide,
  show,
  topviewId,
  widget,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const featureToggles = useSelector((state: RootState) => state.settings.settings?.featureToggles);
  const [selectedType, setSelectedType] = useState(widget ? widget.type : null);
  const [navSelected, setNavSelected] = useState('parameters');
  const [onCreationRules, setOnCreationRules] = useState([]);

  const selectWidgetType = newType => {
    setSelectedType(newType);
    setNavSelected('parameters');
  };

  const handleSelectNav = eventKey => {
    setNavSelected(eventKey);
  };

  const handleHide = () => {
    setSelectedType(widget ? widget.type : null);
    setNavSelected('parameters');
    onHide();
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = getFormData('WidgetForm');
    const data: any = {
      ...omit(formData, ['shape']),
      type: selectedType,
    };

    if (selectedType !== widgetTypes.GAUGE
      && selectedType !== widgetTypes.CIRCULAR) {
      data.format = {
        type: 'shape',
        shape: formData.shape,
      };
    }

    if (widget && widget.id) {
      reduxOperations.topviews.updateWidget(topviewId, widget.id, data)(dispatch)
        .then(() => {
          handleHide();
          showSuccess(t('showSuccessUpdated'));
        })
        .catch(() => {
          showError(t('operationFailed'));
        });
    } else {
      const topview = topviews.find(tv => tv.id === topviewId);
      getImageDimensions(topview?.backgroundURL || emptyTopview)
        .then(dimension => {
          const newY = Math.round((dimension.height * 3) / 100);
          const newH = Math.round((dimension.height * 5) / 100);
          const newW = Math.round((dimension.width * 5) / 100);

          const newData = onCreationRules.length
            ? {
              ...omit(data, ['h', 'w', 'x', 'y', 'z']), y: newY, h: newH, w: newW, rules: onCreationRules,
            }
            : { ...omit(data, ['h', 'w', 'x', 'y', 'z']), y: newY, h: newH, w: newW };

          reduxOperations.topviews.createWidget(topviewId, newData)(dispatch)
            .then(() => {
              handleHide();
              showSuccess(t('showSuccessCreated'));
              selectWidgetType(null);
            })
            .catch(() => {
              showError(t('operationFailed'));
            });
          setOnCreationRules([]);
        }).catch(() => {
          showError(t('operationFailed'));
          setOnCreationRules([]);
        });
    }
  };

  const getTypeCard = card => (
    <button
      className="tileType"
      onClick={() => selectWidgetType(card.type)}
      type="submit"
      key={card.type}
    >
      <FontAwesome icon={card.icon} size="3x" height="51" />
      {t(card.name)}
    </button>
  );

  const showBackArrow = (text = null) => (
    <>
      <FontAwesome
        icon="arrow-left"
        className="backButtonArrow"
        size="sm"
        onClick={() => selectWidgetType(null)}
      />
      {text}
    </>
  );

  let title: any = '';
  if (selectedType === null) {
    title = t('chooseType');
  } else if (t(selectedType)) {
    title = showBackArrow(t(selectedType));
  } else {
    title = showBackArrow();
  }

  return (
    <DefaultModal
      title={title}
      show={show}
      closePopup={handleHide}
      children={(
        <>
          {selectedType && (
            <NavigationTabs
              navSelected={navSelected}
              onSelect={eventKey => handleSelectNav(eventKey)}
              isNew={!widget}
              selectedObject={widget}
              type={selectedType}
            />
          )}
          {(navSelected === 'color' || !shouldDisplayFormContent(navSelected)) && (
            <RuleEditor
              selectedObjectId={widget?.id}
              rules={widget?.rules}
              tileWidgetType={widget?.type}
              updateFunction={(modifiedItemId, widgetId, rules) => (
                reduxOperations.topviews.updateWidget(modifiedItemId, widgetId, rules)(dispatch)
              )}
              modifiedItemId={topviewId}
              type={navSelected}
              onCreationRules={onCreationRules}
              setOnCreationRules={setOnCreationRules}
            />
          )}
          {(!widget || (navSelected !== 'color' && shouldDisplayFormContent(navSelected))) && (
            <div>
              {selectedType ? (
                <WidgetEdition
                  widgetType={selectedType}
                  widget={widget}
                  onHide={handleHide}
                  handleSubmit={val => handleSubmit(val)}
                  topviewId={topviewId}
                  navSelected={navSelected}
                />
              ) : (
                <div className="tileTypeGrid">
                  {widgetOptions
                    .filter(card => card.name !== 'prompt' || featureToggles?.AI?.promptTile)
                    .map(card => getTypeCard(card))}
                </div>
              )}
            </div>
          )}
        </>
      )}
    />
  );
};

TopviewWidgetEditionForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  topviewId: PropTypes.string.isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
  }),
};
TopviewWidgetEditionForm.defaultProps = {
  widget: undefined,
};

export default TopviewWidgetEditionForm;
