import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavDropdown, MenuItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { FontAwesome } from '~UI';
import LogoBlack from '~images/Logo_header_black.png';
import LogoWhiteSanta from '~images/Logo_header_white_santa.png';
import directLinks from '~utils/directLinks';
import { isRedirected } from '~utils/LinkRedirection';
import { capitalizeFirstLetter } from '~utils/capitalizeFirstLetter';
import { reduxOperations } from '~services';
import { getSocket } from '~services/socket';
import { SMALL_PHONE_WIDTH_FULL_MENU, MIN_WIDTH_FULL_MENU, MAX_WIDTH_STANDARD_PHONE } from '~utils/constants';
import LanguagePopup from '~components/Popups/LanguagePopup/LanguagePopup';
import BugReportPopup from '../../Popups/BugReportPopup/BugReportPopup';
import { UserModificationPopup } from '../../../pages/Config/Users/UserModificationPopup';
import { documentationOptions } from './documentationOptions';
import { getBackLocation } from './utils';
import './TopMenu.scss';

const MAX_NAME_LENGTH = 15;

const changeLanguage = lng => {
  i18n.changeLanguage(lng);
};
const configPath = directLinks.utils.configPathFrom;

const TopMenu = ({ modifyIcon }) => {
  const socket = getSocket();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const language = useSelector(state => state.views.language);
  const disabledRedirectedInteractions = useSelector(state => state.settings?.settings?.disabledRedirectedInteractions);
  const isFullscreen = useSelector(state => state.views.isFullscreen);
  const connexion = useSelector(state => state.session.connexion);
  const dashboards = useSelector(state => state.dashboards?.dashboards);
  const topviews = useSelector(state => state.topviews?.topviews);
  const users = useSelector(state => state.users?.users);
  const reports = useSelector(state => state.reports?.reports);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const rules = useSelector(state => state.triggers);
  const theme = useSelector(state => state.session.theme);
  const redirectLocations = useSelector(state => state.redirectHistory.redirectHistory.locations);
  const userId = useSelector(state => state.session.connexion.infos.id);

  const history = useHistory();
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showBugPopup, setShowBugPopup] = useState(false);
  const [showLanguagePopup, setShowLanguagePopup] = useState(false);
  const [showUserPopup, setShowUserPopup] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [isHelpButtonClicked, setIsHelpButtonClicked] = useState(false);

  const handleResize = () => setWindowWidth(window.innerWidth);

  const handleSelectionParametres = selection => {
    const { id } = connexion.infos;

    if (selection.type === 'language') {
      if (isRedirected(id)) {
        dispatch(reduxOperations.views.setLanguage(selection.language));
        changeLanguage(selection.language);
      } else {
        dispatch(reduxOperations.users.updateUser({ language: selection.language }, userId))
          .then(() => {
            dispatch(reduxOperations.views.setLanguage(selection.language));
            changeLanguage(selection.language);
          });
      }
    } else if (selection.type === 'theme') {
      dispatch(reduxOperations.session.setTheme('Dark'));
    } else if (selection.type === 'deconnexion') {
      dispatch(reduxOperations.session.logout());
      socket.disconnect();
    } else if (selection.type === 'bugreport') {
      setShowBugPopup(true);
    } else if (selection.type === 'profile') {
      setShowUserPopup(true);
    }
  };

  const handleChangeFullscreenMode = () => {
    const elem = document.documentElement;
    if (isFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullscreen) { /* Firefox */
        document.mozCancelFullscreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
      dispatch(reduxOperations.views.setFullscreen(false));
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullscreen) { /* Firefox */
        elem.mozRequestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
      dispatch(reduxOperations.views.setFullscreen(true));
    }
  };

  const handleHelpButton = () => {
    setPageNo(1);
    setShowBugPopup(true);
    setIsHelpButtonClicked(true);
  };

  const handleOpenDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getTitle = () => {
    const { id, name } = connexion.infos;

    if (isRedirected(id)) {
      return t('redirected');
    }

    let title = name;
    if (title.length > MAX_NAME_LENGTH) {
      [title] = name.split(' ');
      if (title.length > MAX_NAME_LENGTH) {
        title = `${name.substring(0, MAX_NAME_LENGTH - 3)}...`;
      }
    }
    return title;
  };

  const getDropDown = () => {
    const { id } = connexion.infos;
    const checkIcon = (
      <>
        <FontAwesome icon="check" />
        &nbsp;
      </>
    );

    return (
      <NavDropdown
        id="user-dropdown"
        className={`TopMenu__Dropdown ${(isRedirected(id) && disabledRedirectedInteractions) ? 'redirected' : ''}`}
        eventKey={2}
        title={getTitle()}
        onSelect={handleSelectionParametres}
        open={isDropdownOpen}
        onClick={handleOpenDropdown}
        active={isDropdownOpen}
        onToggle={handleOpenDropdown}
      >
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'profile' }}>
            <div className="dropdownItemLink">
              <FontAwesome icon="user" />
                &nbsp;&nbsp;
              {t('myProfile')}
            </div>
          </MenuItem>
        )}
        {!isRedirected(id) && (
          <MenuItem divider />
        )}
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'bugreport' }} onClick={() => setPageNo(2)}>
            <div className="dropdownItemLink">
              {t('reportAnAnomaly')}
            </div>
          </MenuItem>
        )}
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'bugreport' }} onClick={() => setPageNo(4)}>
            <div className="dropdownItemLink">
              {t('featureRequest')}
            </div>
          </MenuItem>
        )}
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'bugreport' }} onClick={() => setPageNo(5)}>
            <div className="dropdownItemLink">
              {t('requestSupport')}
            </div>
          </MenuItem>
        )}
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'documentation' }} href={documentationOptions[language]} target="_blank">
            <div className="dropdownItemLink">
              {t('documentationWebsite')}
            </div>
          </MenuItem>
        )}
        {!isRedirected(id) && (
          <MenuItem divider />
        )}
        {!isRedirected(id) && (
          <MenuItem eventKey={{ type: 'language', language: language === 'en' ? 'fr' : 'en' }}>
            <div className="dropdownItemLink">
              {language === 'fr' && checkIcon}
              Français
              {' / '}
              {language === 'en' && checkIcon}
              English
            </div>
          </MenuItem>
        )}
        {!(disabledRedirectedInteractions && isRedirected(id)) && (
          <MenuItem divider />
        )}
        {!(disabledRedirectedInteractions && isRedirected(id)) && (
          <MenuItem eventKey={{ type: 'deconnexion' }}>
            <div className="logOut dropdownItemLink">
              {t('logOut')}
            </div>
          </MenuItem>
        )}
      </NavDropdown>
    );
  };

  const { id } = connexion.infos;

  let iiIcon;
  if (isRedirected(id)) {
    iiIcon = (<img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" height="25" />);
  } else {
    iiIcon = (
      <Link to={directLinks.home()}>
        <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" height="25" />
      </Link>
    );
  }

  const userDropdown = (
    <div
      className={`${isDropdownOpen ? 'dropdownItems opened' : 'dropdownItems'} ${(isRedirected(id) && disabledRedirectedInteractions) ? 'redirected' : ''}`}
      onClick={handleOpenDropdown}
    >
      {getDropDown()}
    </div>
  );

  const fullScreenIcon = !(disabledRedirectedInteractions && isRedirected(id)) ? (
    <div
      role="button"
      className="interactive"
      onClick={handleChangeFullscreenMode}
    >
      <FontAwesome icon={isFullscreen ? 'compress' : 'expand'} />
    </div>
  ) : null;

  const languageButton = (
    <div
      role="button"
      className="languageButton interactive"
      onClick={() => setShowLanguagePopup(true)}
    >
      <FontAwesome icon="globe" />
      &nbsp;
      {language.charAt(0).toUpperCase() + language.slice(1)}
    </div>
  );

  const helpButton = (
    <div
      role="button"
      className="interactive helpButton"
      onClick={handleHelpButton}
    >
      <FontAwesome icon="question-circle-o" style={{ fontSize: '22px', color: '#0078FF' }} />
    </div>
  );
  const arrSize = redirectLocations.length || 0;

  const nextLocation = arrSize ? redirectLocations[arrSize - 1] : getBackLocation(history);

  const parseHistory = () => {
    const match = history.location.pathname.split('/');
    const base = (
      <Link to={directLinks.home()}>
        <FontAwesome icon="home" style={{ fontSize: '18px', marginRight: '5px' }} />
      </Link>
    );

    if (match[1] === 'home') {
      return null;
    }

    if (match[1] === 'dashboard') {
      const dashboard = dashboards?.find(dash => dash.id === match[2]);
      const link = (dashboard && (
        <>
          {' > '}
          <Link to={directLinks.dashboard(match[2])}>{dashboard.name}</Link>
        </>
      ));
      return (
        <>
          {base}
          {' > '}
          <Link to={directLinks.dashboards()}>{t('dashboards')}</Link>
          {link}
        </>
      );
    }

    if (match[1] === 'report') {
      const report = reports?.find(rep => rep.id === match[2]);
      const link = (report && (
        <>
          {' > '}
          <Link to={directLinks.report(match[2])}>{report.name}</Link>
        </>
      ));
      return (
        <>
          {base}
          {' > '}
          <Link to={directLinks.reports()}>{t('reports')}</Link>
          {link}
        </>
      );
    }

    if (match[1] === 'config') {
      if (!match[2]) {
        return (
          <>
            {base}
            {' > '}
            <Link to={directLinks.config()}>{t('dashboards')}</Link>
          </>
        );
      }

      if (match[2] === 'ruleEdition') {
        const rule = rules?.find(trigger => trigger.id === match[3]);
        const link = (rule && (
          <>
            {' > '}
            {rule.name}
          </>
        ));
        return (
          <>
            {base}
            {' > '}
            <Link to={configPath(directLinks.ruleEngine())}>{t('ruleEngine')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'dashboard') {
        const dashboard = dashboards?.find(dash => dash.id === match[3]);
        const link = (dashboard && (
          <>
            {' > '}
            <Link to={directLinks.configDashboard(match[3])}>{dashboard.name}</Link>
          </>
        ));

        return (
          <>
            {base}
            {' > '}
            <Link to={directLinks.config()}>{t('dashboards')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'topview') {
        const topview = (topviews || []).find(view => view.id === match[3]);
        const link = (topview && (
          <>
            {' > '}
            <Link to={directLinks.configTopview(match[3])}>{topview.name}</Link>
          </>
        ));
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/topviews">{t('topViews')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'stream') {
        const stream = streams?.find(s => s.id === match[3]);
        const link = (stream && (
          <>
            {' > '}
            <Link to={`/config/stream/${stream.id}/inputs`}>{stream.name}</Link>
          </>
        ));
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/streams">{t('streams')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'machine') {
        const machine = machines?.find(m => m.id === match[3]);
        const link = (machine && (
          <>
            {' > '}
            <Link to={`/config/machine/${machine.id}`}>{machine.name}</Link>
          </>
        ));
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/machines">{t('machines')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'user') {
        const user = users?.find(view => view.id === match[3]);
        const link = (user && (
          <>
            {' > '}
            <Link to={`/config/user/${user.id}`}>{user.name}</Link>
          </>
        ));
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/users">{t('users')}</Link>
            {link}
          </>
        );
      }

      if (match[2] === 'images') {
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/images">Images</Link>
          </>
        );
      }

      if (match[2] === 'streams') {
        return (
          <>
            {base}
            {' > '}
            <Link to="/config/streams">{t('streams')}</Link>
          </>
        );
      }

      if (match[2] === 'triggers' || match[2] === 'actions') {
        return (
          <>
            {base}
            {' > '}
            <Link to={`/config/${match[2]}`}>{t('rulesEngine')}</Link>
          </>
        );
      }

      return (
        <>
          {base}
          {' > '}
          <Link to={`/config/${match[2]}`}>{t(match[2]) ? t(match[2]) : capitalizeFirstLetter(match[2])}</Link>
        </>
      );
    }

    if (match[1] === 'topviews') {
      return (
        <>
          {base}
          {' > '}
          <Link to={directLinks.topviews()}>{t('topViews')}</Link>
        </>
      );
    }

    if (match[1] === 'topview') {
      const topview = (topviews || []).find(view => view.id === match[2]);
      const link = (topview && (
        <>
          {' > '}
          <Link to={directLinks.topview(match[2])}>{topview.name}</Link>
        </>
      ));
      return (
        <>
          {base}
          {' > '}
          <Link to={directLinks.topviews()}>{t('topViews')}</Link>
          {link}
        </>
      );
    }

    if (match[1] === 'dashboards') {
      return (
        <>
          {base}
          {' > '}
          <Link to={directLinks.dashboards()}>{t('dashboards')}</Link>
        </>
      );
    }

    return (
      <>
        {base}
        {' > '}
        <Link to={`${match[1]}`}>{t(match[1]) ? t(match[1]) : capitalizeFirstLetter(match[1])}</Link>
      </>
    );
  };

  useEffect(() => {
    handleResize();
    dispatch(reduxOperations.users.fetchOneUser(id));
  }, []);

  useEffect(() => {
    if (!showUserPopup) {
      dispatch(reduxOperations.users.fetchOneUser(id));
    }
  }, [showUserPopup]);

  const breadcrumb = parseHistory();

  return (
    <div
      className="TopMenu"
      data-testid="TopMenu"
    >
      <div
        className={`${windowWidth < MIN_WIDTH_FULL_MENU ? 'smallInline' : 'inline'} left`}
        data-testid="TopMenu-BackArrow"
      >
        {
          !isRedirected(id) && nextLocation && (
            <Link
              to={nextLocation}
              onClick={() => dispatch(reduxOperations.redirectHistory.redirectHistoryPop())}
            >
              <FontAwesome icon="arrow-left" className="backButtonArrow" />
            </Link>
          )
        }
        {
          !isRedirected(id) && (
            <span className="bread-crumb">
              {breadcrumb}
            </span>
          )
        }
      </div>

      <div
        className={`${windowWidth < MAX_WIDTH_STANDARD_PHONE ? 'noLogo' : 'inline logo'}`}
        data-testid="TopMenu-Logo"
      >
        {windowWidth > MAX_WIDTH_STANDARD_PHONE && iiIcon}
      </div>

      <div
        className={`${windowWidth < MIN_WIDTH_FULL_MENU ? 'bigInline' : 'inline'} right`}
      >
        {windowWidth > SMALL_PHONE_WIDTH_FULL_MENU && modifyIcon && (
          <div className="editIcon">
            {modifyIcon}
          </div>
        )}
        {isRedirected(id) && languageButton}
        {!isRedirected(id) && helpButton}
        {windowWidth > MAX_WIDTH_STANDARD_PHONE && fullScreenIcon}
        {userDropdown}
      </div>

      <LanguagePopup
        show={showLanguagePopup}
        onHide={() => setShowLanguagePopup(false)}
        language={language}
      />

      <BugReportPopup
        show={showBugPopup}
        closePopup={() => setShowBugPopup(false)}
        location={location}
        pageNo={pageNo}
        isHelpButtonClicked={isHelpButtonClicked}
      />

      <UserModificationPopup
        isProfile
        modifiedItemId={id}
        onHide={() => setShowUserPopup(false)}
        show={showUserPopup}
      />
    </div>
  );
};

TopMenu.propTypes = {
  modifyIcon: PropTypes.element,
};
TopMenu.defaultProps = {
  modifyIcon: null,
};

export default withRouter(TopMenu);
