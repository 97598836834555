import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import types from './types';

const streamPropertyPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  variable: PropTypes.string,
});

const sensorPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  topicIn: PropTypes.string,
  topicOut: PropTypes.string,
  properties: PropTypes.arrayOf(
    streamPropertyPropTypes,
  ),
  createdAt: PropTypes.number,
  modifiedAt: PropTypes.number,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
});

const streamsPropTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([
    sensorPropTypes,
  ]),
);

function streams(state = null, action) {
  switch (action.type) {
    case types.GET_STREAMS_RESPONSE:
      return action.payload.streams;

    case types.ADD_STREAM_RESPONSE:
      return state.concat([action.payload.stream]);

    case types.UPDATE_STREAM_RESPONSE: // fall-through
    case types.ADD_STREAM_PROPERTY_RESPONSE:
    case types.UPDATE_STREAM_PROPERTY_RESPONSE:
    case types.DELETE_STREAM_PROPERTY_RESPONSE:
    case types.UPDATE_INPUTS_RESPONSE:
      return state.map(s => (
        (s.id === action.payload.stream.id) ? action.payload.stream : s
      ));

    case types.DELETE_STREAM_RESPONSE:
      return state.filter(s => s.id !== action.payload.streamId);
    default:
      return state;
  }
}

export const propTypes = {
  streams: nullable(streamsPropTypes),
};

export default streams;
