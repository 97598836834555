import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import HttpStatus from 'http-status-codes';
import Cleave from 'cleave.js/react';
import { Checkbox, Input, Select } from '@intelligenceindustrielle/react-ui-components';
import MetaDataDisplay from '~/pages/Config/MetaDataDisplay';
import { reduxOperations } from '~services';
import {
  CancelButton, CheckboxToggle, DeleteButton, DefaultModal, FontAwesome, SubmitButton,
} from '~UI';
import { getFormData, padding } from '~utils';
import { splitTime } from '~utils/time';
import { showError, showSuccess } from '~utils/toast';

const propTypes = {
  // explicitly given props
  show: PropTypes.bool,
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func,
};

const defaultProps = {
  onHide: () => { },
  show: false,
  modifiedItemId: '',
};

const ShiftPopUpForm = ({ show, modifiedItemId, onHide }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const shifts = useSelector(state => state.shifts.shifts);
  const users = useSelector(state => state.users.users);

  const getDaysTemplate = () => [
    { name: t('Monday'), value: 1 },
    { name: t('Tuesday'), value: 2 },
    { name: t('Wednesday'), value: 3 },
    { name: t('Thursday'), value: 4 },
    { name: t('Friday'), value: 5 },
    { name: t('Saturday'), value: 6 },
    { name: t('Sunday'), value: 0 },
  ];

  const getDaysForm = () => {
    const container = document.querySelector('#daysContainer');
    const daysChecked = container.querySelectorAll("input[type='checkbox']:checked");
    return Array.from(daysChecked).map(d => d.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('shiftForm');

    if (!formData.name || !formData.startTime || !formData.endTime) {
      showError(t('showErrorFields'));
      return;
    }

    const shiftFields = {
      name: formData.name,
      hourStart: splitTime(formData.startTime).hours,
      minuteStart: splitTime(formData.startTime).minutes,
      hourEnd: splitTime(formData.endTime).hours,
      minuteEnd: splitTime(formData.endTime).minutes,
      days: getDaysForm(),
      timezone: formData.timezone,
      isPlannedForProduction: formData.isPlannedForProduction,
    };

    if (modifiedItemId.length > 0) {
      dispatch(reduxOperations.shifts.updateShift(modifiedItemId, shiftFields))
        .then(() => {
          onHide();
          showSuccess(t('showSuccessUpdated'));
          const shift = shifts.find(s => s.id === modifiedItemId);
          dispatch(reduxOperations.commands.commandsSave({
            undoAction: { action: dispatch(reduxOperations.shifts.updateShift), params: [modifiedItemId, shift] },
            redoAction: { action: dispatch(reduxOperations.shifts.updateShift), params: [modifiedItemId, shiftFields] },
          }));
        })
        .catch(error => {
          if (error.code === HttpStatus.CONFLICT) {
            const sameName = shifts.find(s => s.name === formData.name && s.id !== modifiedItemId);
            if (sameName) {
              showError(`${t('conflictError')}: ${t('shiftName')}`);
            } else {
              showError(t('overlapInShifts'));
            }
          }
        });
    } else {
      dispatch(reduxOperations.shifts.addShift(shiftFields))
        .then(res => {
          onHide();
          showSuccess(t('showSuccessAdded'));
          dispatch(reduxOperations.commands.commandsSave({
            undoAction: { action: dispatch(reduxOperations.shifts.deleteShift), params: [res.payload.id] },
            redoAction: { action: dispatch(reduxOperations.shifts.addShift), params: [shiftFields] },
          }));
        })
        .catch(error => {
          if (error.code === HttpStatus.CONFLICT) {
            const sameName = shifts.find(s => s.name === formData.name && s.id !== modifiedItemId);
            if (sameName) {
              showError(`${t('conflictError')}: ${t('shiftName')}`);
            } else {
              showError(t('overlapInShifts'));
            }
          }
        });
    }
  };

  const handleDelete = () => {
    const formData = getFormData('shiftForm');
    const shiftFields = {
      name: formData.name,
      hourStart: splitTime(formData.startTime).hours,
      minuteStart: splitTime(formData.startTime).minutes,
      hourEnd: splitTime(formData.endTime).hours,
      minuteEnd: splitTime(formData.endTime).minutes,
      days: getDaysForm(),
      timezone: formData.timezone,
      isPlannedForProduction: formData.isPlannedForProduction,
    };
    dispatch(reduxOperations.shifts.deleteShift(modifiedItemId))
      .then(() => onHide(), showSuccess(t('shiftDeleted')));
    dispatch(reduxOperations.commands.commandsSave({
      undoAction: { action: dispatch(reduxOperations.shifts.addShift), params: [shiftFields] },
      redoAction: { action: dispatch(reduxOperations.shifts.deleteShift), params: [modifiedItemId] },
    }));
  };

  let shift = null;
  if (modifiedItemId.length > 0) {
    shift = JSON.parse(JSON.stringify(shifts.find(s => s.id === modifiedItemId)));
  }

  if (shift === null) {
    shift = {};
    shift.name = '';
    shift.hourStart = '';
    shift.minuteStart = '';
    shift.hourEnd = '';
    shift.minuteEnd = '';
    shift.days = [0, 1, 2, 3, 4, 5, 6];
    shift.isPlannedForProduction = true;
  } else {
    shift.hourStart = padding(shift.hourStart);
    shift.minuteStart = padding(shift.minuteStart);
    shift.hourEnd = padding(shift.hourEnd);
    shift.minuteEnd = padding(shift.minuteEnd);
  }

  return (
    <DefaultModal
      title={modifiedItemId ? t('modifyShift') : t('createShift')}
      show={show}
      closePopup={onHide}
      children={(
        <form
          id="shiftForm"
          onSubmit={handleSubmit}
        >
          <div className="inputTitle">{t('shiftName')}</div>
          <Input
            defaultValue={shift.name}
            name="name"
            type="text"
          />

          <div className="inputTitle">{t('timezone')}</div>
          <Select
            name="timezone"
            options={moment.tz.names().map(tz => ({ value: tz, label: tz.replace(/_/g, ' ') }))}
            defaultValue={shift.timezone || moment.tz.guess()}
          />

          <div className="inputTitle">{t('shiftStartTime')}</div>
          <Cleave
            placeholder={t('shiftStartTime')}
            options={{
              time: true,
              timePattern: ['h', 'm'],
            }}
            value={`${shift.hourStart}:${shift.minuteStart}`}
            name="startTime"
          />

          <div className="inputTitle">{t('shiftEndTime')}</div>
          <Cleave
            placeholder={t('shiftEndTime')}
            options={{
              time: true,
              timePattern: ['h', 'm'],
            }}
            value={`${shift.hourEnd}:${shift.minuteEnd}`}
            name="endTime"
          />

          <div id="daysContainer">
            <div className="inputTitle">{t('days')}</div>
            {
              getDaysTemplate().map(day => (
                <div>
                  <Checkbox
                    defaultChecked={(shift.days || []).includes(day.value)}
                    key={day.name}
                    label={day.name}
                    size={16}
                    value={day.value}
                  />
                </div>
              ))
            }
            <div>
              <FontAwesome icon="info-circle" />
              {` ${t('shiftsAdvice')}`}
            </div>
          </div>

          <div className="inputTitle">
            {t('isPlannedForProduction')}
            &nbsp;
            <CheckboxToggle
              name="isPlannedForProduction"
              defaultChecked={shift.isPlannedForProduction}
            />
          </div>

          {modifiedItemId && (
            <MetaDataDisplay
              createdAt={shift?.createdAt}
              createdBy={shift?.createdBy}
              modifiedAt={shift?.modifiedAt}
              modifiedBy={shift?.modifiedBy}
              users={users}
            />
          )}

          <div className="buttonsHolder flexSpaceBetween">
            {
              modifiedItemId.length > 0 ? (
                <DeleteButton handleDelete={handleDelete} askConfirmation />
              ) : <div />
            }
            <div>
              <CancelButton onClick={onHide} />
              <SubmitButton
                label={modifiedItemId ? t('modify') : t('add')}
                onClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

ShiftPopUpForm.propTypes = propTypes;
ShiftPopUpForm.defaultProps = defaultProps;

export default ShiftPopUpForm;
