import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import MetaDataDisplay from '~/pages/Config/MetaDataDisplay';
import { DeleteButton, CancelButton, SubmitButton } from '~UI';
import { ActionType } from '~services/actions/types';
import { reduxOperations } from '~services';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';

const MachineGeneral = ({ machineId, onHide }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const actions = useSelector(state => state.actions);
  const machines = useSelector(state => state.machines);
  const streams = useSelector(state => state.streams);

  const machine = machines.find(elem => elem.id === machineId) || {};
  const streamOptions = sortArray('alphabetically', streams, 'name').map(stream => ({ label: stream.name, value: stream.id }));

  const buttons = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
    { label: 'ES', value: 'es' },
  ];

  const users = useSelector(state => state.users.users);

  const updateStopCauseNames = (stopCauses, language) => stopCauses.map(stopCause => {
    const newStopCause = { ...stopCause };
    switch (language) {
      case 'en':
        newStopCause.name = stopCause.nameEN || stopCause.name;
        break;
      case 'fr':
        newStopCause.name = stopCause.nameFR || stopCause.name;
        break;
      case 'es':
        newStopCause.name = stopCause.nameES || stopCause.name;
        break;
      default:
        break;
    }

    if (newStopCause.subMenu) {
      newStopCause.subMenu = updateStopCauseNames(newStopCause.subMenu, language);
    }

    return newStopCause;
  });

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('GeneralForm');
    if (machine.language !== formData.language) {
      formData.stopCauses = updateStopCauseNames(machine.stopCauses, formData.language);
    }

    dispatch(reduxOperations.machines.updateMachine(machineId, formData))
      .then(() => {
        showSuccess(t('operationSucceeded'));
        onHide();
      })
      .catch(error => showError(error.status));
  };

  function verifyDependencies() {
    const atLeastOneMachineStateActionUsesThisMachine = actions
      .filter(action => action.type === ActionType.MACHINE_STATE)
      .some(action => (action.params.machineId && (typeof action.params.machineId === 'string' && action.params.machineId.includes(machineId))));
    const atLeastOnePartActionUsesThisMachine = actions
      .filter(action => action.type === ActionType.PART)
      .some(action => (action.params.machineId && (typeof action.params.machineId === 'string' && action.params.machineId.includes(machineId))));
    const atLeastOneEventActionUsesThisMachine = actions
      .filter(action => action.type === ActionType.EVENT)
      .some(action => (action.params.machineId && (typeof action.params.machineId === 'string' && action.params.machineId.includes(machineId))));

    return !(atLeastOneMachineStateActionUsesThisMachine
      || atLeastOnePartActionUsesThisMachine
      || atLeastOneEventActionUsesThisMachine);
  }

  const handleDelete = () => {
    if (!verifyDependencies()) {
      showError(t('isReferencedError'));
    } else {
      dispatch(reduxOperations.machines.deleteMachine(machineId));
      onHide();
    }
  };
  return (
    <form id="GeneralForm" onSubmit={handleSubmit}>
      <div className="inputTitle">{t('name')}</div>
      <input
        name="name"
        className="fullwidth"
        type="text"
        defaultValue={machine.name}
        placeholder="Name"
      />

      <div className="inputTitle">{t('stream')}</div>
      <Select
        name="streamId"
        options={streamOptions}
        defaultValue={machine.streamId}
        placeholder={t('select')}
      />
      <br />
      <div className="inputTitle">{t('language')}</div>
      <ButtonGroup
        name="language"
        buttons={buttons}
        defaultValue={machine.language}
      />

      {
        machineId && (
          <MetaDataDisplay
            createdAt={machine?.createdAt}
            createdBy={machine?.createdBy}
            modifiedAt={machine?.modifiedAt}
            modifiedBy={machine?.modifiedBy}
            users={users}
          />
        )
      }

      <div className="buttonsHolder flexSpaceBetween">
        <DeleteButton
          handleDelete={handleDelete}
          askConfirmation
          style={{ marginLeft: 0 }}
        />
        <div>
          <CancelButton onClick={onHide} />
          <SubmitButton label={t('modify')} onClick={handleSubmit} />
        </div>
      </div>
    </form>
  );
};

MachineGeneral.propTypes = {
  machineId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

export { MachineGeneral };
