/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@intelligenceindustrielle/react-ui-components';
import LogoBlack from '~images/Logo_header_black.png';
import LogoWhiteSanta from '~images/Logo_header_white_santa.png';
import API from '~services/endpoints';
import { RootState } from '~services/store';
import { DefaultSubmitButton } from '~UI/Buttons';
import { DefaultModal } from '~UI/DefaultModal';
import { TextArea, FontAwesome } from '~components/UI';
import './BugReportPopup.scss';

enum BugPriority {
  FEATURE = 'feature',
  CONTACT = 'contact',
  CRITICAL = 'critical',
  MAJOR = 'major',
  MINOR = 'minor',
}

const anomalyList = [
  { level: 1, category: 'critical' },
  { level: 2, category: 'major' },
  { level: 3, category: 'minor' },
];

const unsupportedFiles = ['gif', 'jpg', 'mp4'];

const BugReportPopup = ({ closePopup, show, pageNo, isHelpButtonClicked }) => {
  const { t } = useTranslation();

  const theme = useSelector((state: RootState) => state.session.theme);

  const [bugPriority, setBugPriority] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState<FileList | null | undefined>();
  const [pageNumber, setPageNumber] = useState(pageNo || 1);
  const [visitedPage1, setVisitedPage1] = useState(false);
  const [isFileSupported, setIsFileSupported] = useState(true);

  useEffect(() => {
    setPageNumber(pageNo);
    if (pageNo === 4) {
      setBugPriority(BugPriority.FEATURE);
    } else if (pageNo === 5) {
      setBugPriority(BugPriority.CONTACT);
    }
  }, [pageNo]);

  const navigateToPage = page => {
    if (pageNumber === 1 && page !== 1) {
      setVisitedPage1(true);
    }
    setPageNumber(page);
  };

  const closeBugReport = () => {
    setPageNumber(pageNo);
    setBugPriority('');
    setDescription('');
    setFiles(null);
    setVisitedPage1(false);
    setIsFileSupported(true);
    closePopup();
  };

  function renderBackArrow() {
    if (isHelpButtonClicked && visitedPage1) {
      return (
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          onClick={() => setPageNumber(1)}
        />
      );
    }
    return null;
  }

  const handleSubmit = async () => {
    setPageNumber(6);

    const formData = new FormData();
    if (files) {
      formData.append('file', files[0]);
    }
    formData.append('priorityLevel', bugPriority);
    formData.append('description', description);
    formData.append('pathname', window.location.href);

    API.createBug(formData).catch(() => undefined);

    setTimeout(() => {
      closeBugReport();
    }, 2200);
  };

  function handleChangeDescription(e) {
    setDescription(e.currentTarget?.value || e);
  }

  function getContents() {
    switch (pageNumber) {
      case 1:
        return (
          <div data-testid="BugReportPopup-page1">
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="50%" style={{ minWidth: '170px', marginBottom: '40px' }} />
            <Button
              className="priorityButton"
              label={t('reportAnAnomaly')}
              onClick={() => navigateToPage(2)}
            />
            <Button
              className="priorityButton"
              label={t('proposeFeature')}
              onClick={() => { navigateToPage(4); setBugPriority(BugPriority.FEATURE); }}
            />
            <Button
              className="priorityButton"
              label={t('requestSupport')}
              onClick={() => { navigateToPage(5); setBugPriority(BugPriority.CONTACT); }}
            />
          </div>
        );
      case 2:
        return (
          <div data-testid="BugReportPopup-page2">
            {renderBackArrow()}
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="20%" style={{ minWidth: '170px' }} />
            <div className="title">{t('bugPriorityLevel')}</div>
            <div style={{ marginTop: '30px' }}>
              {anomalyList.map(anomaly => {
                if (anomaly.level) {
                  return (
                    <Button
                      key={anomaly.level}
                      className="priorityButton"
                      label={`${t('level')} ${anomaly.level} - ${t(anomaly.category)}`}
                      onClick={() => {
                        setPageNumber(3);
                        setBugPriority((BugPriority as {[key: string]: BugPriority})[anomaly.category.toUpperCase()]);
                      }}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
      case 3:
        return (
          <div data-testid="BugReportPopup-page3">
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              onClick={() => setPageNumber(2)}
            />
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="20%" style={{ minWidth: '170px' }} />
            <div className="title">
              {t('describeTheAnomaly')}
            </div>
            <div className="description">
              <TextArea
                value={description}
                onChange={handleChangeDescription}
                placeholder={t('bugReportDescription')}
              />
            </div>
            <div className="title">{t('optionalImageFile')}</div>
            <div>
              <input
                type="file"
                id="fileUploadInput"
                onChange={e => {
                  if (e.target.files && e.target.files.length > 0) {
                    const fileExtension = e.target.files[0]?.name?.split('.').pop()?.toLowerCase();
                    if (unsupportedFiles.includes(fileExtension as string)) {
                      setIsFileSupported(false);
                      setFiles(null);
                    } else {
                      setIsFileSupported(true);
                      setFiles(e.target.files);
                    }
                  } else {
                    setFiles(null);
                  }
                }}
                style={{ visibility: 'hidden', width: '0' }}
              />
              <label htmlFor="fileUploadInput">{files?.length ? files[0].name : t('selectFile')}</label>
              <Icon
                icon="info-circle"
                tooltipLabel={t('bugReportFilesTooltip')}
              />
            </div>
            <div className="errorUnsupportedFile">
              {!isFileSupported && (
                <div>
                  <span>{t('fileNotSelected')}</span>
                  &nbsp;
                  <span>{t('bugReportFilesTooltip')}</span>
                </div>
              )}
            </div>
            <DefaultSubmitButton
              className="active startButton"
              label={t('submit')}
              onClick={handleSubmit}
              isActive
            />
          </div>
        );
      case 4:
        return (
          <div data-testid="BugReportPopup-page4">
            {renderBackArrow()}
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="20%" style={{ minWidth: '170px' }} />
            <div className="title">{t('proposeFeature')}</div>
            <div className="description">
              <TextArea
                value={description}
                onChange={handleChangeDescription}
                placeholder={t('proposeFeatureDescription')}
              />
            </div>
            <div className="title">{t('optionalImageFile')}</div>
            <div>
              <input
                type="file"
                id="fileUploadInput"
                onChange={e => setFiles(e.target.files)}
                style={{ visibility: 'hidden', width: '0' }}
              />
              <label htmlFor="fileUploadInput">{files ? files[0]?.name : t('selectFile')}</label>
            </div>
            <DefaultSubmitButton
              className="active startButton"
              label={t('submit')}
              onClick={handleSubmit}
              isActive
            />
          </div>
        );
      case 5:
        return (
          <div data-testid="BugReportPopup-page4">
            {renderBackArrow()}
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="20%" style={{ minWidth: '170px' }} />
            <div className="title">{t('requestSupport')}</div>
            <div className="description">
              <TextArea
                value={description}
                onChange={handleChangeDescription}
                placeholder={t('requestSupportDescription')}
              />
            </div>
            <div className="title">{t('optionalImageFile')}</div>
            <div>
              <input
                type="file"
                id="fileUploadInput"
                onChange={e => setFiles(e.target.files)}
                style={{ visibility: 'hidden', width: '0' }}
              />
              <label htmlFor="fileUploadInput">{files ? files[0]?.name : t('selectFile')}</label>
            </div>
            <DefaultSubmitButton
              className="active startButton"
              label={t('submit')}
              onClick={handleSubmit}
              isActive
            />
          </div>
        );
      case 6:
        return (
          <div data-testid="BugReportPopup-page5">
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" width="50%" style={{ minWidth: '170px' }} />
            <div className="title">{t('thankFeedback')}</div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <DefaultModal
      closePopup={(!description && (!files || files.length === 0)) ? closeBugReport : () => undefined}
      headless
      show={show}
      children={(
        <div
          className="BugReportPopup"
          data-testid="BugReportPopup-container"
        >
          <button
            type="button"
            className="close BugReportPopupCloseButton"
            onClick={closeBugReport}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">{t('close')}</span>
          </button>
          {getContents()}
        </div>
      )}
    />
  );
};

BugReportPopup.propTypes = {
  closePopup: PropTypes.func,
  show: PropTypes.bool.isRequired,
  pageNo: PropTypes.number,
  isHelpButtonClicked: PropTypes.bool.isRequired,
};
BugReportPopup.defaultProps = {
  closePopup: () => undefined,
  pageNo: 1,
};

export default BugReportPopup;
