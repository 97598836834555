import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import './MetaDataDisplay.scss';

const MetaDataDisplay = ({
  createdAt, createdBy, modifiedAt, modifiedBy, users,
}) => {
  const { t } = useTranslation();

  const findUserName = userId => users?.find(user => user.id === userId)?.name || t('unknownUser');

  return (
    <div className="metaData">
      <div className="metaDataText">
        {createdAt && createdBy && (
          <div
            className="metaDataDate"
            title={DateTime.fromMillis(createdAt).toISO()}
          >
            {t('createdBy')}
            &nbsp;
            <b>{findUserName(createdBy)}</b>
            &nbsp;
            {DateTime.fromMillis(createdAt).toRelative()}
          </div>
        )}
        {modifiedAt && modifiedBy && (
          <div
            className="metaDataDate"
            title={DateTime.fromMillis(modifiedAt).toISO()}
          >
            {t('modifiedBy')}
            &nbsp;
            <b>{findUserName(modifiedBy)}</b>
            &nbsp;
            {DateTime.fromMillis(modifiedAt).toRelative()}
          </div>
        )}
      </div>
    </div>
  );
};

MetaDataDisplay.propTypes = {
  createdAt: PropTypes.number,
  createdBy: PropTypes.string,
  modifiedAt: PropTypes.number,
  modifiedBy: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

MetaDataDisplay.defaultProps = {
  createdAt: null,
  createdBy: null,
  modifiedAt: null,
  modifiedBy: null,
  users: [],
};

export default MetaDataDisplay;
