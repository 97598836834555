import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const operationPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  skuNumber: PropTypes.string,
  description: PropTypes.string,
  requiredQuantity: PropTypes.number,
  unitComletionTime: PropTypes.number,
  machines: PropTypes.arrayOf(PropTypes.string),
  nbsignalsPerPartIn: PropTypes.number,
  nbSignalsPerPartOut: PropTypes.number,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
  createdAt: PropTypes.number,
  modifiedAt: PropTypes.number,
});

const operationsPropTypes = PropTypes.arrayOf(operationPropTypes);

function operations(state = null, action) {
  switch (action.type) {
    case types.GET_OPERATIONS_RESPONSE:
      return action.payload.operations;
    case types.ADD_OPERATION_RESPONSE:
      return state.concat(action.payload.operation);
    case types.UPDATE_OPERATION_RESPONSE:
      return state.map(o => (o.id === action.payload.operation.id ? action.payload.operation : o));
    case types.DELETE_OPERATION_RESPONSE:
      return state.filter(o => o.id !== action.payload.operation.id);
    default:
      return state;
  }
}

export const propTypes = {
  operation: nullable(operationPropTypes),
  operations: nullable(operationsPropTypes),
};

export default combineReducers({ operations });
