import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Icon } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle } from '~UI/index';
import { zoomTypes } from '~utils/types';

import {
  shouldDisplayContentInput,
  shouldDisplayTitleInput,
  shouldDisplayZoomInput,
} from './display';

const FormStyle = ({
  isTile, selectedObject, type,
}) => {
  const { t } = useTranslation();

  const [titleSizeToggle, setTitleSizeToggle] = useState(!!selectedObject.titleSize);
  const [titleSize, setTitleSize] = useState(selectedObject.titleSize);

  const [contentSizeToggle, setContentSizeToggle] = useState(!!selectedObject.contentSize);
  const [contentSize, setContentSize] = useState(selectedObject.contentSize);

  const [selectedZoom, setSelectedZoom] = useState(selectedObject.zoom || zoomTypes.MEDIUM);

  const zoomButtons = [
    { label: t('small'), value: zoomTypes.SMALL },
    { label: t('medium'), value: zoomTypes.MEDIUM },
    { label: t('large'), value: zoomTypes.LARGE },
  ];

  const [h, setH] = useState(String(selectedObject.h ?? '') || '');
  const [w, setW] = useState(String(selectedObject.w ?? '') || '');
  const [x, setX] = useState(String(selectedObject.x ?? '') || '');
  const [y, setY] = useState(String(selectedObject.y ?? '') || '');
  const [z, setZ] = useState(String(selectedObject.z ?? '') || '');

  function handleNumberChange(e) {
    const { name, value } = e.target;
    const intValue = value.replace(/[^0-9]/g, '');
    switch (name) {
      case 'h':
        setH(intValue);
        break;
      case 'w':
        setW(intValue);
        break;
      case 'x':
        setX(intValue);
        break;
      case 'y':
        setY(intValue);
        break;
      case 'z':
        setZ(intValue);
        break;
      default:
        break;
    }
  }

  return (
    <div>
      {!isTile && (
        <div>
          <div className="inputRow">
            <div className="inputTitle">
              x &nbsp;
              <input
                name="x"
                type="number"
                pattern="\d*"
                min={0}
                value={x}
                onChange={handleNumberChange}
              />
            </div>
            <div className="inputTitle">
              y &nbsp;
              <input
                name="y"
                type="number"
                pattern="\d*"
                min={0}
                value={y}
                onChange={handleNumberChange}
              />
            </div>
          </div>
          <div className="inputRow">
            <div className="inputTitle">
              h &nbsp;
              <input
                name="h"
                type="number"
                pattern="\d*"
                min={1}
                value={h}
                onChange={handleNumberChange}
              />
            </div>
            <div className="inputTitle">
              w &nbsp;
              <input
                name="w"
                type="number"
                pattern="\d*"
                min={1}
                value={w}
                onChange={handleNumberChange}
              />
            </div>
          </div>
          <div className="inputTitle">
            z
            <Icon
              icon="info-circle"
              tooltipLabel={t('zTooltip')}
            />
            &nbsp;
            <input
              name="z"
              type="number"
              pattern="\d*"
              step={1}
              min={0}
              value={z}
              onChange={handleNumberChange}
            />
          </div>
        </div>
      )}

      {(isTile || shouldDisplayTitleInput(type)) && (
        <div>
          <div className="inputTitle">
            {`${t('fixTitleSize')}`}
            &nbsp;
            <CheckboxToggle
              controlledCheck={titleSizeToggle}
              onChange={() => setTitleSizeToggle(prevState => !prevState)}
            />
          </div>
          <div className="inputTitle">
            {titleSizeToggle && (
              <div className="inputTitle">
                {`${t('fontSize')}`}
              </div>
            )}
            <input
              name="titleSize"
              type={titleSizeToggle ? 'number' : 'hidden'}
              className="fullwidth"
              min={1}
              max={50}
              value={titleSizeToggle ? titleSize : ''}
              onChange={e => setTitleSize(e.currentTarget.value)}
            />
          </div>
        </div>
      )}

      {(shouldDisplayContentInput(type)) && (
        <div>
          <div className="inputTitle">
            {`${t('fixContentSize')}`}
            &nbsp;
            <CheckboxToggle
              controlledCheck={contentSizeToggle}
              onChange={() => setContentSizeToggle(prevState => !prevState)}
            />
          </div>
          <div className="inputTitle">
            {contentSizeToggle && (
              <div className="inputTitle">
                {`${t('fontSize')}`}
              </div>
            )}
            <input
              name="contentSize"
              type={contentSizeToggle ? 'number' : 'hidden'}
              className="fullwidth"
              min={1}
              value={contentSizeToggle ? contentSize : ''}
              onChange={e => setContentSize(e.currentTarget.value)}
            />
          </div>
        </div>
      )}

      {shouldDisplayZoomInput(type) && (
        <div className="zoomButtons">
          <span style={{ fontSize: '17px' }}>
            {`${t('displaySize')}: `}
          </span>
          <input
            type="hidden"
            name="zoom"
            value={selectedZoom}
          />
          <ButtonGroup
            buttons={zoomButtons}
            onChange={setSelectedZoom}
            value={selectedZoom}
          />
        </div>
      )}
    </div>
  );
};

FormStyle.propTypes = {
  isTile: PropTypes.bool,
  selectedObject: PropTypes.shape({
    contentSize: PropTypes.number,
    titleSize: PropTypes.number,
    zoom: PropTypes.string,
    h: PropTypes.number,
    w: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }),
  type: PropTypes.string.isRequired,
};
FormStyle.defaultProps = {
  isTile: false,
  selectedObject: {},
};

export { FormStyle };
