import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const SELECTED_USER_INITIAL_STATE = null;

const userPropTypes = PropTypes.oneOfType([
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    username: PropTypes.string,
    phoneNumber: PropTypes.string,
    country: PropTypes.string,
    display: PropTypes.bool,
    image: PropTypes.string,
    language: PropTypes.string,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
    permissions: PropTypes.shape({
      features: PropTypes.arrayOf(PropTypes.string),
      contents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
      })),
    }),
  }),
  PropTypes.arrayOf(PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.string),
    contents: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })),
  })),
]);

const selectedUserPropTypes = userPropTypes;

function selectedUser(state = SELECTED_USER_INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_USER_PERMISSIONS_RESPONSE:
      return action.payload.user;
    case types.GET_USER_RESPONSE:
    case types.UPDATE_USER_RESPONSE:
    case types.SEND_WELCOME_EMAIL_RESPONSE:
      if (state.id === action.payload.user.id) {
        return { ...state, ...action.payload.user };
      }
      return state;
    case types.ADD_DASHBOARD_RESPONSE: {
      const newStateAdd = Object.assign([], state);
      newStateAdd.permissions.contents.push({ type: 'Dashboard', id: action.payload.id });
      return newStateAdd;
    }
    case types.DELETE_DASHBOARD_RESPONSE: {
      const newStateDelete = Object.assign([], state);
      newStateDelete.permissions.contents = newStateDelete.permissions.contents
        .filter(d => d.id !== action.payload.dashboardId);
      return newStateDelete;
    }
    default:
      return state;
  }
}

const usersPropTypes = PropTypes.arrayOf(userPropTypes);
function users(state = null, action) {
  switch (action.type) {
    case types.GET_USER_RESPONSE:
      if (!state) {
        return [action.payload.user];
      }
      return state.map(user => {
        if (user.id === action.payload.user.id) {
          return { ...user, ...action.payload.user };
        }
        return user;
      });
    case types.GET_ALL_USERS_PERMISSIONS_RESPONSE:
    case types.GET_USERS_RESPONSE:
      if (!action.payload.error) {
        return action.payload.users;
      }
      return state;
    case types.ADD_USER_RESPONSE: {
      let response;
      try {
        response = state.concat(action.payload);
      } catch (error) {
        response = action.payload;
      }
      return response;
    }
    case types.UPDATE_USER_RESPONSE:
    case types.SEND_WELCOME_EMAIL_RESPONSE:
      if (!state) {
        return state;
      }
      return state.map(user => {
        if (user.id === action.payload.user.id) {
          return { ...user, ...action.payload.user };
        }
        return user;
      });
    case types.DELETE_USER_RESPONSE:
      if (!action.payload.error) {
        return state.filter(user => user.id !== action.payload.userId);
      }
      return state;
    default:
      return state;
  }
}

export const propTypes = {
  selectedUser: nullable(selectedUserPropTypes),
  users: nullable(usersPropTypes),
};

export default combineReducers({
  selectedUser,
  users,
});
