import PropTypes from 'prop-types';

export enum ActionType {
  API_CALL = 'APICallAction',
  EMAIL = 'EmailAction',
  EVENT = 'EventAction',
  MACHINE_PARAM = 'MachineParamAction',
  MACHINE_STATE = 'MachineStateAction',
  MQTT = 'MQTTAction',
  PART = 'PartAction',
  PERFORMANCE = 'PerformanceAction',
  PUSH_NOTIFICATION = 'PushNotificationAction',
  SMS = 'SMSAction',
  STOPWATCH = 'StopwatchAction',
  VARIABLE = 'VariableAction',
}

export interface Action {
  id: string;
  name: string;
  type: ActionType;
  createdAt: number;
  modifiedAt: number;
  createdBy: string;
  modifiedBy: string;
}

export const ActionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ActionType)),
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
  createdAt: PropTypes.number,
  modifiedAt: PropTypes.number,
});

