import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import TimeLineEventsPopup from '~components/Popups/MachinePopup/TimelineEventsPopup';
import { getAlives } from '~services/alives/endpoints';
import { showSuccess } from '~utils/toast';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import statusColors from '~styles/statusColors';
import { MachineEventPopup, PerformanceEventPopup, FontAwesome } from '~UI';
import { Timeline } from '~UI/svg';
import { useShift } from '~utils/hooks';
import { configurationFeature } from '~utils/featureToggles';
import Tick from '~utils/Tick';
import { serverTime, getShiftFromTimestamp, dateToStringWithoutYearAndMillis, getDataRangeStartEnd } from '~utils/time';
import { DEFAULT_ALIVE_DELAY } from '~utils/constants';
import { findNestedCause } from '~utils/nestedStopCause';
import { getBlocks, getDataBlocks } from './utils';
import TileContents from '../TileContents';
import '../TileContents.scss';

const flattenCauses = causes => (
  [].concat(...causes.map(c => [c].concat(flattenCauses(c.subMenu || []))))
);

const TimelineTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const container = useRef();

  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);
  const language = useSelector(state => state.views.language);
  const { t } = useTranslation();
  const settings = useSelector(state => state.settings.settings);
  const showAlive = useSelector(state => state.settings.settings.featureToggles.features.showAlive);
  const machines = useSelector(state => state.machines);
  const lastPatchedEvent = useSelector(state => state.events.lastPatchedEvent);

  const [alives, setAlives] = useState([]);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTS, setCurrentTS] = useState(serverTime());
  const [showEvents, setShowEvents] = useState(false);

  const [currentShift, shifts] = useShift(tile.machineId);

  const [startTime, setStartTime] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift).start);
  const [endTime, setEndTime] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift).end);
  const [popupEvent, setPopupEvent] = useState(null);
  const [performanceEvents, setPerformanceEvents] = useState([]);
  const [performancePopupEvent, setPerformancePopupEvent] = useState(null);
  const [partEvents, setPartEvents] = useState([]);
  const [hasUnmounted, setHasUnmounted] = useState(false);
  const idleTimerRef = useRef(null);
  const newShift = useRef(useShift(tile.machineId)[0]);
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const updateCurrentTS = () => {
    if (!hasUsedArrows) {
      setCurrentTS(serverTime());
      setStartTime(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift).start);
      setEndTime(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift).end);
    }
  };

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  const machine = machines.find(m => m.id === tile.machineId);

  const handleSocketEvent = newEvent => {
    // If it's an update, modify an existing element's stopcause
    // If it's a new event, add it to the list
    // If we are not in the current timeline interval, do not add it to the list
    if (JSON.stringify(newShift.current) !== JSON.stringify(currentShift)) return;
    if (newEvent.type === 'MachineStatus' && newEvent.machineId === tile.machineId) {
      setEvents(prevEvents => {
        const event = prevEvents.find(elem => newEvent.id === elem.id);
        if (event) {
          event.motif = newEvent.motif;
          event.stopCauseEN = newEvent.stopCauseEN;
          event.stopCauseFR = newEvent.stopCauseFR;
          event.stopCauseES = newEvent.stopCauseES;
          event.stopCauseId = newEvent.stopCauseId;
          event.causeColor = findNestedCause(machine?.stopCauses, newEvent.stopCauseId)?.color;
          event.comments = newEvent.comments;
          event.resolutions = newEvent.resolutions;
          event.file = newEvent.file;
        } else if (!hasUsedArrows) {
          newEvent.causeColor = findNestedCause(machine?.stopCauses, newEvent.stopCauseId)?.color;
          prevEvents.push(newEvent);
        }
        return prevEvents;
      });
    } else if (newEvent.type === 'PerformanceEvent' && newEvent.machineId === tile.machineId) {
      setPerformanceEvents(prevPerformanceEvents => {
        const performanceEvent = prevPerformanceEvents.find(elem => newEvent.id === elem.id);
        if (performanceEvent) {
          performanceEvent.motif = newEvent.motif;
          performanceEvent.performanceCauseId = newEvent.performanceCauseId;
          performanceEvent.causeColor = findNestedCause(machine?.performanceCauses, newEvent.performanceCauseId)?.color;
          performanceEvent.comments = newEvent.comments;
          performanceEvent.resolutions = newEvent.resolutions;
          performanceEvent.file = newEvent.file;
        } else if (!hasUsedArrows) {
          newEvent.causeColor = findNestedCause(machine?.stopCauses, newEvent.stopCauseId)?.color;
          prevPerformanceEvents.push(newEvent);
        }
        return prevPerformanceEvents;
      });
    } else if (newEvent.type === 'PartEvent' && newEvent.machineId === tile.machineId && newEvent.eventType === 'SCRAP') {
      setPartEvents(prevPartEvents => {
        const partEvent = prevPartEvents.find(elem => newEvent.id === elem.id);
        if (partEvent) {
          partEvent.motif = newEvent.motif;
          partEvent.defectCauseId = newEvent.defectCauseId;
          partEvent.causeColor = findNestedCause(machine?.defectCauses, newEvent.defectCauseId)?.color;
          partEvent.operator = newEvent.infosObject.operator;
          partEvent.workOrder = newEvent.infosObject.workOrder;
          partEvent.skuNumber = newEvent.infosObject.skuNumber;
          partEvent.operation = newEvent.infosObject.operation;
          partEvent.comments = newEvent.comments;
          partEvent.resolutions = newEvent.resolutions;
          partEvent.file = newEvent.file;
        } else if (!hasUsedArrows) {
          newEvent.causeColor = findNestedCause(machine?.stopCauses, newEvent.stopCauseId)?.color;
          newEvent.operator = newEvent.infosObject.operator;
          newEvent.workOrder = newEvent.infosObject.workOrder;
          newEvent.skuNumber = newEvent.infosObject.skuNumber;
          newEvent.operation = newEvent.infosObject.operation;
          prevPartEvents.push(newEvent);
        }
        return prevPartEvents;
      });
    }
  };

  const handleSocketEventDeleted = event => {
    if (JSON.stringify(newShift.current) !== JSON.stringify(currentShift)) return;
    if (event.type === 'MachineStatus' && event.machineId === tile.machineId) {
      setEvents(prevEvents => prevEvents.filter(elem => event.id !== elem.id));
    }
  };

  const handleSocketAlive = newAlive => {
    if (JSON.stringify(newShift.current) !== JSON.stringify(currentShift)) return;
    const { start } = currentShift;
    const { streamId } = machines.find(m => m.id === tile.machineId) || {};
    if (showAlive && newAlive.streamId === streamId && start.getTime() === startTime) {
      setAlives(prevAlives => {
        prevAlives.push(newAlive);
        return prevAlives;
      });
    }
  };

  const fetchEvents = async (start, end) => {
    // Get the last event before the time period started
    const filter1 = {
      type: 'MachineStatus',
      machineId: tile.machineId,
      timestamp: {
        $lt: start,
      },
    };
    const sort1 = { timestamp: -1 };
    const eventBefore = (await API.getEvents(filter1, sort1, 1)).events;
    // Get all the events in the time period
    const filter2 = {
      type: 'MachineStatus',
      machineId: tile.machineId,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const sort2 = { timestamp: 1 };
    const { events: newEvents } = await API.getEvents(filter2, sort2);

    const eventsWithColor = [...eventBefore, ...newEvents].map(event => {
      event.causeColor = findNestedCause(machine?.stopCauses, event.stopCauseId)?.color;
      return event;
    });

    if (!hasUnmounted) {
      setEvents(eventsWithColor);
      setIsLoading(false);
    }
  };

  const fetchPerformanceEvents = async (start, end) => {
    // Get the last event before the time period started
    const filter1 = {
      type: 'PerformanceEvent',
      machineId: tile.machineId,
      timestamp: {
        $lt: start,
      },
    };
    const sort1 = { timestamp: -1 };
    const eventBefore = (await API.getEvents(filter1, sort1, 1)).events;
    // Get all the events in the time period
    const filter2 = {
      type: 'PerformanceEvent',
      machineId: tile.machineId,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const sort2 = { timestamp: 1 };
    const { events: newEvents } = await API.getEvents(filter2, sort2);

    const eventsWithColor = [...eventBefore, ...newEvents].map(event => {
      event.causeColor = findNestedCause(machine?.performanceCauses, event.performanceCauseId)?.color;
      return event;
    });

    if (!hasUnmounted) {
      setPerformanceEvents(eventsWithColor);
    }
  };

  const fetchPartEvents = async (start, end) => {
    // Get all the events in the time period
    const filter = {
      type: 'PartEvent',
      eventType: 'SCRAP',
      machineId: tile.machineId,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const sort = { timestamp: 1 };
    const { events: newEvents } = await API.getEvents(filter, sort);

    const eventsWithColor = [...newEvents].map(event => {
      event.operator = event.infosObject.operator;
      event.workOrder = event.infosObject.workOrder;
      event.skuNumber = event.infosObject.skuNumber;
      event.operation = event.infosObject.operation;
      event.causeColor = findNestedCause(machine?.defectCauses, event.defectCauseId)?.color;
      return event;
    });

    if (!hasUnmounted) {
      setPartEvents(eventsWithColor);
    }
  };

  const fetchAlives = async (start, end) => {
    if (showAlive) {
      // Get the last alive before the time period started
      const { streamId } = machines.find(m => m.id === tile.machineId) || {};
      const filter1 = {
        timestamp: {
          $lt: start,
        },
      };
      const sort1 = { timestamp: -1 };
      const aliveBefore = (await getAlives(streamId, filter1, sort1, 1, 'stream')).alives;
      // Get all the alives in the time period
      const filter2 = {
        timestamp: {
          $gte: start,
          $lt: end,
        },
      };
      const sort2 = { timestamp: 1 };
      const { alives: newAlives } = await getAlives(streamId, filter2, sort2, 0, 'stream');
      setAlives([...aliveBefore, ...newAlives]);
    }
  };

  const findStopCauseHierarchy = (stopCauses, causeId) => {
    for (const cause of stopCauses) {
      if (cause.subMenu) {
        for (const subCause of cause.subMenu) {
          if (subCause.id === causeId) {
            return [cause.name];
          }
          const found = findStopCauseHierarchy(cause.subMenu, causeId);
          if (found.length > 0) {
            return [cause.name, ...found];
          }
        }
      }
    }
    return [];
  };

  const formatDuration = durationInMilliseconds => {
    const durationInSeconds = durationInMilliseconds / 1000;
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    if (hours > 0) {
      return `${hours}h ${minutes}min ${seconds}sec`;
    }
    return `${minutes}min ${seconds}sec`;
  };

  const evaluateEvents = (startEvaluate, endEvaluate) => {
    const aliveDelay = tile.aliveDelay || DEFAULT_ALIVE_DELAY;
    const defaultUnfilledStopCauseColor = settings ? settings.defaultUnfilledStopCauseColor : null;
    const flattenedStopCauses = machine && flattenCauses(machine.stopCauses);
    const data = [];

    const blocks = getBlocks(alives, events, startEvaluate, endEvaluate, aliveDelay, showAlive);

    for (let i = 0; i < blocks.length; i += 1) {
      const {
        start, end, status, motif, id, params, causeId,
      } = blocks[i];
      let label;
      let color;
      let hierarchyCause;

      const duration = formatDuration(end - start);

      if (status === 'POWEREDOFF') {
        label = t('poweredOff');
        color = statusColors.Unavailable;
      } else if (status === 'UNAVAILABLE') {
        label = t('Unavailable');
        color = statusColors.Unavailable;
      } else if (status === 'ON') {
        label = t('operating');
        color = statusColors.On;
      } else if (status === 'OFF') {
        if (motif) {
          const stopCause = flattenedStopCauses.find(s => s.id === causeId);
          const hierarchy = stopCause ? findStopCauseHierarchy(flattenedStopCauses, stopCause.id) : [];
          hierarchyCause = hierarchy.join(' -> ');
          switch (language) {
            case 'en':
              label = stopCause?.nameEN || motif;
              break;
            case 'fr':
              label = stopCause?.nameFR || motif;
              break;
            case 'es':
              label = stopCause?.nameES || motif;
              break;
            default:
              label = motif;
              break;
          }
          color = (stopCause?.color) ? stopCause.color : statusColors.Off;
        } else {
          label = t('stopped');
          color = defaultUnfilledStopCauseColor || statusColors.Unknown;
        }
      } else {
        label = t('unknown');
        color = statusColors.Unknown;
      }

      data.push({
        start,
        end,
        duration,
        label,
        color,
        id,
        status,
        params,
        hierarchyCause,
      });
    }

    return data;
  };

  const evaluatePerformanceEvents = (startEvaluate, endEvaluate) => {
    const defaultUnfilledPerformanceCauseColor = '#FF00FF';
    const flattenedPerformanceCauses = machine && flattenCauses(machine.performanceCauses);
    const data = [];

    const blocks = getDataBlocks(performanceEvents, startEvaluate, endEvaluate);

    for (let i = 0; i < blocks.length; i += 1) {
      const {
        start, end, status, motif, id,
      } = blocks[i];
      let label;
      let color;
      let hierarchyCause;

      const duration = formatDuration(end - start);

      if (status === 'START') {
        if (motif) {
          label = motif;
          const performanceCause = flattenedPerformanceCauses.find(p => p.name === label);
          const hierarchy = performanceCause ? findStopCauseHierarchy(
            flattenedPerformanceCauses, performanceCause.id,
          ) : [];
          hierarchyCause = hierarchy.join(' -> ');
          color = (performanceCause && performanceCause.color)
            ? performanceCause.color
            : defaultUnfilledPerformanceCauseColor;
        } else {
          label = t('detectedPerformanceDrop');
          color = defaultUnfilledPerformanceCauseColor;
        }
        data.push({
          start,
          end,
          duration,
          label,
          color,
          id,
          status,
          hierarchyCause,
        });
      }
    }
    return data;
  };

  const openPopup = () => {
    setShowEvents(true);
  };

  const handleModifyEvent = () => {
    showSuccess(t('eventUpdated'));
  };

  const onPopupHide = () => {
    setPopupEvent(null);
    setPerformancePopupEvent(null);
  };

  const showPopup = event => setPopupEvent(event);

  const showPerformancePopup = performancePopupEventArg => setPerformancePopupEvent(performancePopupEventArg);

  const canNavigateTime = goToPrevious => {
    // To navigate through shift, we use the timestamp as a cursor.
    // If you go to the next shift, take the end timestamp + 1.
    // For the previous shift, take the start timestamp - 1.
    const shiftCursor = goToPrevious ? startTime - 1 : endTime + 1;
    if (tile.intervalType === 'shift' || !tile.intervalType) {
      const shift = getShiftFromTimestamp(shifts, shiftCursor);
      if (goToPrevious) {
        const difference = currentTS - startTime;
        const hoursBackAllowed = configurationFeature.isUserAllowedAccessAdmin() ? Infinity : 168;
        return Math.floor(difference / 3600000) < hoursBackAllowed;
      }
      return shift && shift.start < currentTS;
    }
    if (tile.intervalType === 'lastHour' || tile.intervalType === 'last24Hours') {
      const duration = tile.intervalType === 'lastHour' ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000;
      return goToPrevious ? startTime - duration >= 0 : endTime < currentTS;
    }
    return false;
  };

  const navigateTime = goToPrevious => {
    if (!canNavigateTime(goToPrevious)) {
      if (!goToPrevious) {
        setHasUsedArrows(false);
      }
      return;
    }
    let newStartTime;
    let newEndTime;

    if (tile.intervalType === 'shift' || !tile.intervalType) {
      const shiftCursor = goToPrevious ? startTime - 1 : endTime + 1;
      const shift = getShiftFromTimestamp(shifts, shiftCursor);
      newShift.current = shift;
      newStartTime = shift.start.getTime();
      newEndTime = shift.end.getTime();
    } else if (tile.intervalType === 'lastHour' || tile.intervalType === 'last24Hours') {
      const duration = tile.intervalType === 'lastHour' ? 60 * 60 * 1000 : 24 * 60 * 60 * 1000;
      newStartTime = goToPrevious ? startTime - duration : endTime;
      newEndTime = newStartTime + duration;
    }
    setEvents([]);
    setAlives([]);
    setIsLoading(true);
    setStartTime(newStartTime);
    setEndTime(newEndTime);
    fetchEvents(newStartTime, newEndTime);
    fetchPerformanceEvents(newStartTime, newEndTime);
    fetchPartEvents(newStartTime, newEndTime);
    fetchAlives(newStartTime, newEndTime);
    setHasUsedArrows(true);
  };

  const handleOnActive = () => {
    fetchEvents(startTime, endTime);
    fetchPerformanceEvents(startTime, endTime);
    fetchAlives(startTime, endTime);
  };

  const handleOnIdle = () => {
    fetchEvents(startTime, endTime);
    fetchPerformanceEvents(startTime, endTime);
    fetchAlives(startTime, endTime);
    idleTimerRef.current.reset();
  };

  useEffect(() => {
    fetchEvents(startTime, endTime);
    fetchPerformanceEvents(startTime, endTime);
    fetchPartEvents(startTime, endTime);
    fetchAlives(startTime, endTime);
    return () => {
      setHasUnmounted(true);
    };
  }, []);

  useEffect(() => {
    Tick.subscribe(updateCurrentTS, 10);

    return () => {
      Tick.unsubscribe(updateCurrentTS);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    if (!canNavigateTime(false)) {
      setHasUsedArrows(false);
    }
  }, [currentTS, endTime, tile.intervalType, currentShift]);

  useEffect(() => {
    const { start, end } = currentShift;
    // If the machineId has changed or the shift has changed
    setEvents([]);
    setAlives([]);
    setIsLoading(true);
    setStartTime(start.getTime());
    setEndTime(end.getTime());
    fetchEvents(start.getTime(), end.getTime());
    fetchPerformanceEvents(start.getTime(), end.getTime());
    fetchAlives(start.getTime(), end.getTime());
  }, [tile, tile.machineId, currentShift]);

  useEffect(() => {
    socket?.on('alive', handleSocketAlive);
    socket?.on('event', handleSocketEvent);
    socket?.on('eventDeleted', handleSocketEventDeleted);

    return () => {
      socket?.removeListener('alive', handleSocketAlive);
      socket?.removeListener('event', handleSocketEvent);
      socket?.removeListener('eventDeleted', handleSocketEventDeleted);
    };
  }, [socket]);

  useEffect(() => {
    fetchEvents(startTime, endTime);
    fetchPerformanceEvents(startTime, endTime);
    fetchAlives(startTime, endTime);
  }, [lastPatchedEvent]);

  useEffect(() => {
    const now = serverTime();
    let newStartTime;
    let newEndTime;

    if (tile.intervalType === 'lastHour') {
      newStartTime = now - 60 * 60 * 1000;
      newEndTime = now;
    } else if (tile.intervalType === 'last24Hours') {
      newStartTime = now - 24 * 60 * 60 * 1000;
      newEndTime = now;
    } else {
      newStartTime = currentShift.start.getTime();
      newEndTime = currentShift.end.getTime();
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);

    fetchEvents(newStartTime, newEndTime);
    fetchPerformanceEvents(newStartTime, newEndTime);
    fetchPartEvents(newStartTime, newEndTime);
    fetchAlives(newStartTime, newEndTime);
  }, [tile.intervalType, currentShift]);

  const { start } = currentShift;

  const endEventEvaluation = start.getTime() !== startTime ? endTime : currentTS;
  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(startTime))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(endTime))}`
    : '';

  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  const commentEventsCount = [...events, ...performanceEvents, ...partEvents].filter(
    event => event.comments?.length > 0).length;

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
      topRightSection={(
        <div style={{ position: 'relative' }}>
          <FontAwesome
            className="commentButton"
            icon="message"
            onClick={openPopup}
            style={{
              cursor: 'pointer',
              fontSize: 24,
              height: 30,
            }}
          />
          {commentEventsCount > 0 && (
            <div className="timeline-comment-badge">
              {commentEventsCount}
            </div>
          )}
        </div>
      )}
    >
      <TimeLineEventsPopup
        selectedMachineId={machine.id}
        events={[...events, ...performanceEvents, ...partEvents]}
        onHide={() => setShowEvents(false)}
        show={showEvents}
        onModifyEvent={handleModifyEvent}
      />
      <MachineEventPopup
        show={!isInConfigurationMode && !!popupEvent}
        onHide={onPopupHide}
        event={popupEvent}
        machineId={tile.machineId}
      />
      <PerformanceEventPopup
        show={!isInConfigurationMode && !!performancePopupEvent}
        onHide={onPopupHide}
        event={performancePopupEvent}
        machineId={tile.machineId}
      />
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      {isLoading && (
        <Loader />
      )}
      <div className={`timeNavigationContainer ${isLoading && 'hide'}`}>
        <div>{navigationText}</div>
        <div className="timeNavigation">
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-left"
              className="timeNavigationButton"
              style={{
                opacity: !canNavigateTime(true) && 0.4,
                cursor: !canNavigateTime(true) && 'default',
              }}
              onClick={() => navigateTime(true)}
            />
          )}
          <Timeline
            height={start.getTime() !== startTime ? height - 26 : height}
            width={tile.showArrows ? width - 70 : width}
            hasArrows={tile.showArrows}
            domainX={[startTime, endTime]}
            data={evaluateEvents(startTime, endEventEvaluation)}
            performanceData={evaluatePerformanceEvents(startTime, endEventEvaluation)}
            events={events}
            performanceEvents={performanceEvents}
            showPopup={showPopup}
            showPerformancePopup={showPerformancePopup}
          />
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-right"
              className="timeNavigationButton"
              style={{
                opacity: !canNavigateTime(false) && 0.4,
                cursor: !canNavigateTime(false) && 'default',
              }}
              onClick={() => navigateTime(false)}
            />
          )}
        </div>
      </div>
    </TileContents>
  );
};

TimelineTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    machineId: PropTypes.string,
    aliveDelay: PropTypes.number,
    showArrows: PropTypes.bool,
    intervalType: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
};

export default TimelineTile;
