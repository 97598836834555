import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { shouldHaveCurrentValue } from '../NavigationTabs/displayTabs';
import { SubmitButton } from '~UI';
import { showSuccess, showError } from '~utils/toast';
import { getFormData } from '~utils';
import { sortArray } from '~utils/sort';
import RuleContainer from './DnD/RuleContainer';
import RuleForm from './RuleForm';

const propTypes = {
  modifiedItemId: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.string,
      id: PropTypes.string.isRequired,
      condition: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      variableType: PropTypes.string.isRequired,
    }),
  ),
  selectedObjectId: PropTypes.string.isRequired,
  tileWidgetType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateFunction: PropTypes.func.isRequired,
  onCreationRules: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      text: PropTypes.string,
      id: PropTypes.string.isRequired,
      condition: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      variableType: PropTypes.string.isRequired,
    }),
  ),
  setOnCreationRules: PropTypes.func,
};

const RuleEditor = ({
  modifiedItemId, rules, tileWidgetType, selectedObjectId, type, updateFunction, onCreationRules, setOnCreationRules,
}) => {
  const { t } = useTranslation();

  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const machines = useSelector(state => state.machines);
  const stopwatches = useSelector(state => state.stopwatches.stopwatches);

  const [showRuleForm, setShowRuleForm] = useState(false);
  const [currentRule, setCurrentRule] = useState(null);

  useEffect(() => {
    setCurrentRule(null);
    setShowRuleForm(false);
  }, [type]);

  const handleShowARule = rule => {
    if (currentRule === rule || !showRuleForm) {
      setShowRuleForm(true);
    }

    setCurrentRule(rule);
  };

  const updateOrCreateRule = (e, selectedColor, selectedContentColor) => {
    e.preventDefault();
    const formData = getFormData('RuleForm');
    if (formData['bool:valueToCompare'] === 'true' || formData['bool:valueToCompare'] === 'false') {
      formData.valueToCompare = formData['bool:valueToCompare'] === 'true';
    }

    if (!formData.comparator || formData.valueToCompare == null || !formData.variable) {
      showError(t('showErrorFields'));
      return;
    }

    const stopwatch = stopwatches.find(sw => `\${${sw.id}}` === formData.variable);
    const variableType = stopwatch ? 'stopwatch' : 'value';

    let { valueToCompare } = formData;
    if (typeof valueToCompare === 'string') {
      valueToCompare = `"${valueToCompare}"`;
    } else if (typeof valueToCompare === 'boolean') {
      valueToCompare = valueToCompare.toString();
    }

    const data = {
      type,
      id: uuidv4(),
      condition: formData.variable + formData.comparator + valueToCompare,
      variableType,
    };

    if (type === 'color') {
      data.color = selectedColor || currentRule.color || '#fff';
      data.contentColor = selectedContentColor;
    } else if (type === 'text') {
      data.text = formData.text;
      data.textEN = formData.textEN;
      data.textFR = formData.textFR;
      data.textES = formData.textES;
    } else if (type === 'media') {
      data.media = formData.media;
    } else {
      data.trigger = formData.trigger;
    }
    if (!selectedObjectId) {
      if (!currentRule) {
        setOnCreationRules([...onCreationRules, data]);
        showSuccess(t('showSuccessCreated'));
      } else {
        setOnCreationRules(onCreationRules.map(rule => (rule.id === currentRule.id ? data : rule)));
        showSuccess(t('showSuccessUpdated'));
      }
    } else {
      let rulesToPush = [];

      if (!currentRule) {
        rulesToPush = [...rules, data];
        showSuccess(t('showSuccessCreated'));
      } else {
        rulesToPush = rules.map(rule => (rule.id === currentRule.id ? data : rule));
        showSuccess(t('showSuccessUpdated'));
      }
      updateFunction(modifiedItemId, selectedObjectId, { rules: rulesToPush });
    }

    setCurrentRule(null);
    setShowRuleForm(false);
  };

  const duplicateRule = () => {
    if (!currentRule) {
      showError(t('showErrorSelect'));
      return;
    }

    const data = {
      ...currentRule,
      id: uuidv4(),
    };

    if (!selectedObjectId) {
      setOnCreationRules([...onCreationRules, data]);
    } else {
      const rulesToPush = [...rules, data];
      updateFunction(modifiedItemId, selectedObjectId, { rules: rulesToPush });
    }
    showSuccess(t('showSuccessCreated'));

    setCurrentRule(null);
    setShowRuleForm(false);
  };

  const deleteRule = id => {
    if (!selectedObjectId) {
      setOnCreationRules(onCreationRules.filter(rule => rule.id !== id));
    } else {
      const rulesToPush = rules.filter(rule => rule.id !== id);
      const rulesWithoutId = rulesToPush.map(rule => {
        delete rule._id;
        return rule;
      });
      updateFunction(modifiedItemId, selectedObjectId, { rules: rulesWithoutId });
    }
    setCurrentRule(null);
    setShowRuleForm(false);
    showSuccess(t('showSuccessDeleted'));
  };

  const updateRuleArray = newRuleArray => {
    const rulesToPush = rules
      .filter(rule => rule.type !== type)
      .concat(...newRuleArray);

    updateFunction(modifiedItemId, selectedObjectId, { rules: rulesToPush });
    setCurrentRule(null);
    setShowRuleForm(false);
  };

  const typeRules = rules.filter(rule => rule.type === type);
  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const currentValue = { id: 'currentValue', variable: t('currentValue') };
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable');
  const inputsAndVariablesAndStopwatches = [
    ...(shouldHaveCurrentValue(tileWidgetType) && type === 'color' ? [{
      id: currentValue.id,
      name: currentValue.variable,
    }] : []),
    ...inputsAndVariables
      .map(x => ({
        id: x.id,
        name: x.variable,
      }))
      .concat(sortArray('alphabetically', stopwatches, 'name')),
  ];
  const ruleCardProps = {
    currentRule,
    inputsAndVariables,
    stopwatches,
    showRuleForm,
    handleShowARule,
  };

  return (
    <div className="Form simpleFlex">
      <div>
        <br />
        <SubmitButton
          className={(currentRule === null && showRuleForm && 'SelectedButton') || undefined}
          label={t('addRule')}
          onClick={() => handleShowARule(null)}
        />
        <br />
        <br />
        <DndProvider backend={HTML5Backend}>
          <RuleContainer
            ruleCardProps={ruleCardProps}
            arrayOfRules={selectedObjectId ? typeRules : onCreationRules}
            updateRules={updateRuleArray}
          />
        </DndProvider>
      </div>
      {
        showRuleForm && (
          <RuleForm
            dropDownList={inputsAndVariablesAndStopwatches}
            selectedObjectId={selectedObjectId}
            currentRule={currentRule}
            tileWidgetType={tileWidgetType}
            type={type}
            updateOrCreateRule={updateOrCreateRule}
            deleteRule={deleteRule}
            duplicateRule={duplicateRule}
            stopwatches={stopwatches}
          />
        )
      }
    </div>
  );
};

RuleEditor.propTypes = propTypes;
RuleEditor.defaultProps = {
  rules: [],
  onCreationRules: [],
  setOnCreationRules: () => null,
};

export default RuleEditor;
