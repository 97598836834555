import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MetaDataDisplay from '~/pages/Config/MetaDataDisplay';
import { reduxOperations } from '~services';
import {
  CancelButton, DefaultModal, DeleteButton, MultiSelect, SubmitButton,
} from '~UI';
import { getFormData } from '~utils';
import { showError, showHttpError, showSuccess } from '~utils/toast';

const OperationPopupForm = ({ modifiedItemId, onHide, show }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);
  const operations = useSelector(state => state.operations.operations);
  const [operation, setOperation] = useState({ machines: [] });
  const [selectedMachines, setSelectedMachines] = useState([]);
  const users = useSelector(state => state.users.users);

  useEffect(() => {
    const op = operations.find(o => o.id === modifiedItemId) || { machines: [] };
    setOperation(op);
    setSelectedMachines(machines.filter(m => op.machines.includes(m.id)).map(m => ({ label: m.name, value: m.id })));
  }, [show]);

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    const formData = getFormData('OperationForm');
    if (!formData.name || !formData.requiredQuantity || !formData.unitCompletionTime || !formData.skuNumber) {
      showError(t('showErrorFields'));
      return;
    }
    formData.machines = selectedMachines.map(m => m.value);
    if (operation.id) {
      dispatch(reduxOperations.operations.updateOperation(operation.id, formData))
        .then(() => {
          onHide();
          showSuccess(t('showSuccessUpdated'));
        })
        .catch(error => showHttpError(error, language));
    } else {
      dispatch(reduxOperations.operations.addOperation(formData))
        .then(() => {
          onHide();
          showSuccess(t('showSuccessCreated'));
        })
        .catch(error => showHttpError(error, language));
    }
  };

  const handleDelete = () => {
    dispatch(reduxOperations.operations.deleteOperation(operation.id))
      .then(() => {
        onHide();
        showSuccess(t('showSuccessDeleted'));
      })
      .catch(error => showHttpError(error, language));
  };

  return (
    <DefaultModal
      show={show}
      closePopup={onHide}
      title={t('operation')}
      children={(
        <form
          id="OperationForm"
          onSubmit={handleSubmit}
        >
          <div className="inputTitle">{t('name')}</div>
          <input
            name="name"
            type="text"
            className="fullwidth"
            defaultValue={operation.name}
          />
          <div className="inputTitle">{t('skuNumber')}</div>
          <input
            name="skuNumber"
            type="text"
            className="fullwidth"
            defaultValue={operation.skuNumber}
          />
          <div className="inputTitle">{t('description')}</div>
          <input
            name="description"
            type="text"
            className="fullwidth"
            defaultValue={operation.description}
          />
          <div className="inputTitle">{t('requiredQuantity')}</div>
          <input
            name="requiredQuantity"
            type="number"
            min={1}
            className="fullwidth"
            defaultValue={operation.requiredQuantity || 1}
          />
          <div className="inputTitle">{`${t('unitCompletionTime')} (ms)`}</div>
          <input
            name="unitCompletionTime"
            type="number"
            min={1}
            className="fullwidth"
            defaultValue={operation.unitCompletionTime || 1}
          />
          <div className="inputTitle">{t('machines')}</div>
          <MultiSelect
            options={machines.map(m => ({ label: m.name, value: m.id }))}
            value={selectedMachines}
            onChange={options => setSelectedMachines(options)}
          />
          <div className="inputTitle">{t('signalsPerPartIn')}</div>
          <input
            name="nbSignalsPerPartIn"
            type="number"
            min={0}
            step="any"
            className="fullwidth"
            defaultValue={operation.nbSignalsPerPartIn}
          />
          <div className="inputTitle">{t('signalsPerPartOut')}</div>
          <input
            name="nbSignalsPerPartOut"
            type="number"
            min={0}
            step="any"
            className="fullwidth"
            defaultValue={operation.nbSignalsPerPartOut}
          />
          <div className="inputTitle">{t('scheduling')}</div>
          <input
            name="number:order"
            type="number"
            min={1}
            step={1}
            className="fullwidth"
            defaultValue={operation.order}
          />
          {
            operation.id && (
              <MetaDataDisplay
                createdAt={operation?.createdAt}
                createdBy={operation?.createdBy}
                modifiedAt={operation?.modifiedAt}
                modifiedBy={operation?.modifiedBy}
                users={users}
              />
            )
          }
          <div className="buttonsHolder flexSpaceBetween">
            {
              operation.id ? (
                <DeleteButton handleDelete={handleDelete} askConfirmation />
              ) : <div />
            }
            <div>
              <CancelButton onClick={onHide} />
              <SubmitButton />
            </div>
          </div>
        </form>
      )}
    />
  );
};

OperationPopupForm.propTypes = {
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
OperationPopupForm.defaultProps = {
  modifiedItemId: '',
};

export { OperationPopupForm };
