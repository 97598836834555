import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';

import types from './types';

const variablePropTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  expression: PropTypes.string,
  variable: PropTypes.string,
  units: PropTypes.string,
  isEnabled: PropTypes.bool,
  createdAt: PropTypes.number,
  modifiedAt: PropTypes.number,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
});

const variablesPropTypes = PropTypes.arrayOf(variablePropTypes);
function variables(state = null, action) {
  switch (action.type) {
    case types.GET_VARIABLE_INPUTS_RESPONSE:
      return action.payload.variables;
    case types.ADD_VARIABLE_RESPONSE:
      return state.concat(action.payload.variable);
    case types.PATCH_VARIABLE_RESPONSE:
    case types.UPDATE_VARIABLE_RESPONSE:
      if (!state) {
        return state;
      }
      return state.map(variable => (
        (variable.id === action.payload.variable.id) ? action.payload.variable : variable
      ));
    case types.DELETE_VARIABLE_RESPONSE:
      return state.filter(variable => variable.id !== action.payload);

    default:
      return state;
  }
}

export const propTypes = {
  variables: nullable(variablesPropTypes),
};

export default variables;
