import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  withRouter, Route, Switch, Router,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createBrowserHistory } from 'history';
import { withTranslation } from 'react-i18next';
import LogoBlack from '~images/Logo_header_black.png';
import LogoWhiteSanta from '~images/Logo_header_white_santa.png';
import { showSuccess, showError } from '~utils/toast';
import { reduxOperations } from '~services';
import API from '~services/endpoints';
import LoginPage from './LoginPage';
import Footer from '~components/Pages/Footer';
import './VerifyAccount.scss';

let isInitialized = false;
const history = createBrowserHistory();

const propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  updateIsVerification: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const loginRouter = () => (
  <Switch>
    <Route path="/" component={LoginPage} />
  </Switch>
);

const RoutingLogin = () => {
  const router = loginRouter();
  history.push('/');
  return (
    <Router history={history}>
      {router}
    </Router>
  );
};

class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageAllowed: true,
    };
  }

  componentDidMount() {
    if (!isInitialized) {
      this.setIsPageAllowed();
    }
    isInitialized = true;
  }

  setIsPageAllowed = async () => {
    const { updateIsVerification, match } = this.props;
    const { isPageAllowed } = this.state;

    await API.getUserVerification(match.params.id)
      .then(res => {
        this.setState({ isPageAllowed: !res.isVerified });
        isInitialized = false;
        if (isPageAllowed) {
          updateIsVerification(true);
        }
      });
  };

  makeUserVerified = () => {
    const { verifyUser, updateIsVerification, match } = this.props;
    const userId = match.params.id;
    const userData = { isVerified: true };
    updateIsVerification(false);
    verifyUser(userId, userData)
      .then(() => {
        showSuccess('Account verified');
      })
      .catch(error => {
        showError(error.status);
      });
    this.setIsPageAllowed();
  };

  render() {
    const theme = localStorage.getItem('theme');
    const { t } = this.props;
    const { isPageAllowed } = this.state;

    if (isPageAllowed) {
      return (
        <div className="Verify">
          <div className="headerVerify">
            <img id="ii-icon" src={theme === 'Dark' ? LogoWhiteSanta : LogoBlack} alt="K2" height="25" />
          </div>
          <div className="middle">
            <div className="inner">
              <button onClick={this.makeUserVerified} type="submit">
                {t('verifyAndLogin')}
              </button>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
    return RoutingLogin();
  }
}

VerifyAccount.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    selectedUser: state.users.selectedUser,
    connexion: state.session.connexion,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    verifyUser: reduxOperations.users.verifyUser,
    updateIsVerification: reduxOperations.session.updateIsVerification,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VerifyAccount)));
