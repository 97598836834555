import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import MetaDataDisplay from '../../../MetaDataDisplay';

const propTypes = {
  stream: PropTypes.shape({
    name: PropTypes.string.isRequired,
    topicIn: PropTypes.string.isRequired,
    topicOut: PropTypes.string.isRequired,
    periodState: PropTypes.string,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
  }).isRequired,
};

const Sensor = ({ stream }) => {
  const { t } = useTranslation();

  const users = useSelector((state: RootState) => state.users.users);

  return (
    <div>
      <div className="inputTitle">{t('name')}</div>
      <input
        name="name"
        type="text"
        defaultValue={stream ? stream.name : null}
        className="fullwidth"
      />
      <div className="inputTitle">{t('inboundTopic')}</div>
      <input
        name="topicIn"
        type="text"
        defaultValue={stream ? stream.topicIn : null}
        className="fullwidth"
      />
      <div className="inputTitle">{t('outboundTopic')}</div>
      <input
        name="topicOut"
        type="text"
        defaultValue={stream ? stream.topicOut : null}
        className="fullwidth"
      />
      <div className="inputTitle">
        {`${t('periodState')}`}
        <Icon
          icon="info-circle"
          tooltipLabel={t('periodStateInfo')}
        />
      </div>
      <input
        name="number:periodState"
        type="number"
        defaultValue={stream?.periodState || 3600000}
        placeholder="Period state"
      />
      {stream && (
        <MetaDataDisplay
          createdAt={stream?.createdAt}
          createdBy={stream?.createdBy}
          modifiedAt={stream?.modifiedAt}
          modifiedBy={stream?.modifiedBy}
          users={users}
        />
      )}
    </div>
  );
};

Sensor.propTypes = propTypes;

export default Sensor;
